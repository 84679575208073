import axios from "axios";
import "./chatOnline.css";
import serverIp from "../../config/config";

import { useSelector } from "react-redux";

export default function ChatOnline({ onlineUsers, setCurrentChat }) {

  const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

  const handleClick = async (user) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let receiverId = user._id;

      const { data } = await axios.post(`${serverIp}/api/conversations/coach`, { receiverId }, config);
      setCurrentChat(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="chatOnline1">
      {onlineUsers.map((o) => (
        <div className="chatOnlineFriend" onClick={() => handleClick(o)}>
          <div className="chatOnlineImgContainer">
            <img
              className="chatOnlineImg"
              src={
                o?.image
              }
              alt=""
            />
            {/* <div className="chatOnlineBadge"></div> */}
          </div>
          <span className="chatOnlineName">{o?.name}</span>
        </div>
      ))}
    </div>
  );
}