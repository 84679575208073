import { Flex, Icon, Button, Heading, Box, Image, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { TbTrash } from 'react-icons/tb';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { deletePrograms } from '../actions/programsActions';
import { listFeedbackPrograms } from '../actions/programsActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const FeedBackProgListScreen = () => {

	const dispatch = useDispatch();

	const feedbackProgramsList = useSelector((state) => state.feedbackProgramsList);
	const { loading, error, programs } = feedbackProgramsList;

	const programsDelete = useSelector((state) => state.programsDelete);
	const {
		success: successDelete,
	} = programsDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listFeedbackPrograms());
	}, [dispatch, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deletePrograms(id));
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='2' bg='black' px={{ sm: '5px', lg: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Button bgColor='gray.200' color='black' as={RouterLink} to={'/programs/add'}>
						<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Add Programs")}
					</Button>
				</Flex>

				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Flex pt='5px' direction={{ sm: 'column', md: 'row' }} gap='2' flexWrap='wrap' px={{ sm: '5px', md: '66px' }}>
						{programs.programme.map((program) => (
							<Flex _hover={{ textDecor: 'none' }} >
								<Flex p='10px' bgColor='white' rounded='lg' shadow='lg' w={{ sm: '380px', lg: '400px' }} h={{ sm: '110px', lg: '8rem' }} direction='row'>
									<Box as={RouterLink} to={`/feedProg/${program._id}/users`}>
										<Image border='solid' rounded='md' src={program.image} alt={program.name} h='full' w={{ sm: '94px', lg: '110px' }} />
									</Box>
									<Flex w='270px' px={{ sm: '5px', lg: '3px' }} direction='column'>
										<Flex justifyContent='space-between' alignItems='center' overflow='clip'>
											<Heading w='270px' as={RouterLink} to={`/feedProg/${program._id}/users`} fontSize={{ sm: 'md', lg: 'lg' }} overflow='clip'>
												{program.name}
											</Heading>
											<Button bg='none' w={{ sm: '5', lg: '3' }} h={{ sm: '5', lg: '8' }} type='button' onClick={() => deleteHandler(program._id)}>
												<Icon as={TbTrash} boxSize={{ sm: '5', lg: '6' }} fontSize='xl' fontWeight='bold' />
											</Button>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						))}
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default FeedBackProgListScreen;