export const PROGRAMS_LIST_REQUEST = 'PROGRAMS_LIST_REQUEST';
export const PROGRAMS_LIST_SUCCESS = 'PROGRAMS_LIST_SUCCESS';
export const PROGRAMS_LIST_FAIL = 'PROGRAMS_LIST_FAIL';

export const PROGRAMS_DETAILS_REQUEST = 'PROGRAMS_DETAILS_REQUEST';
export const PROGRAMS_DETAILS_SUCCESS = 'PROGRAMS_DETAILS_SUCCESS';
export const PROGRAMS_DETAILS_FAIL = 'PROGRAMS_DETAILS_FAIL';

export const PROGRAMS_CREATE_REQUEST = 'PROGRAMS_CREATE_REQUEST';
export const PROGRAMS_CREATE_SUCCESS = 'PROGRAMS_CREATE_SUCCESS';
export const PROGRAMS_CREATE_FAIL = 'PROGRAMS_CREATE_FAIL';
export const PROGRAMS_CREATE_RESET = 'PROGRAMS_CREATE_RESET';

export const PROGRAMS_DELETE_REQUEST = 'PROGRAMS_DELETE_REQUEST';
export const PROGRAMS_DELETE_SUCCESS = 'PROGRAMS_DELETE_SUCCESS';
export const PROGRAMS_DELETE_FAIL = 'PROGRAMS_DELETE_FAIL';

export const PROGRAMS_UPDATE_REQUEST = 'PROGRAMS_UPDATE_REQUEST';
export const PROGRAMS_UPDATE_SUCCESS = 'PROGRAMS_UPDATE_SUCCESS';
export const PROGRAMS_UPDATE_FAIL = 'PROGRAMS_UPDATE_FAIL';
export const PROGRAMS_UPDATE_RESET = 'PROGRAMS_UPDATE_RESET';

export const FEEDBACK_PROGRAMS_LIST_REQUEST = 'FEEDBACK_PROGRAMS_LIST_REQUEST';
export const FEEDBACK_PROGRAMS_LIST_SUCCESS = 'FEEDBACK_PROGRAMS_LIST_SUCCESS';
export const FEEDBACK_PROGRAMS_LIST_FAIL = 'FEEDBACK_PROGRAMS_LIST_FAIL';