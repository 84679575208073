import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Image,
	AspectRatio,
	Textarea,
	Card,
	CardBody,
	CardFooter,
	Text,
	Divider,
	ButtonGroup
} from '@chakra-ui/react';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import serverIp from '../config/config';
import FormContainer from '../components/FormContainer';
import { listEventDetails, updateEvents } from '../actions/eventsActions';
import { EVENTS_UPDATE_RESET } from '../constants/EventsConstants';
import UploadFile from '../components/UploadFile';
import ImageCropper from '../components/ImageCropper';
import AlertDialogExample from '../components/AlertDialog';

const EventEditScreen = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id: eventId } = useParams();

	const [name, setName] = useState('');
	const [result, setResult] = useState('');
	const [thumbImage, setThumbImage] = useState('');
	const [thumbVideo, setThumbVideo] = useState('');
	const [eventLink, setEventLink] = useState('');
	const [time, setTime] = useState('');
	const [description, setDescription] = useState('');
	const [uploadingImg, setUploadingImg] = useState(false);
	const [uploadingVid, setUploadingVid] = useState(false);
	const [media, setMedia] = useState({ url: null, name: '', type: '', description: '' });
	const [mediaSave, setMediaSave] = useState(false);
	const [mediaEdit, setMediaEdit] = useState(false);
	const [isShown, setIsShown] = useState(true);

	const { t } = useTranslation();

	const eventsDetails = useSelector((state) => state.eventsDetails);
	const { loading, error, events } = eventsDetails;

	const eventsUpdate = useSelector((state) => state.eventsUpdate);
	const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = eventsUpdate;

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: EVENTS_UPDATE_RESET });
			navigate(`/events/${eventId}`);
		} else {
			if (!events.name || events._id !== eventId) {
				dispatch(listEventDetails(eventId));
			} else {
				setName(events.name);
				setResult(events.result);
				setThumbImage(events.thumbImage);
				setThumbVideo(events.thumbVid);
				setEventLink(events.eventLink);
				setTime(events.setTime);
				setDescription(events.description);
				setMedia(events.media ? events.media : { url: null, name: '', type: '', description: '' });
			}
		}
	}, [dispatch, navigate, eventId, events, successUpdate]);

	const onMediaChange = ( index, type, url, name, description) => {
		setMedia({
			type: type,
			url: url,
			name: name,
			description: description
		});
		setMediaSave(true);
		setMediaEdit(false);
	};

	const onMediaEdit = ( index, type, url, name, description) => {
		setMedia({
			type: type,
			url: url,
			name: name,
			description: description
		});
		setMediaSave(true);
		setMediaEdit(false);
	};

	const onMediaSave = ( index, type, url, name, description) => {
		setMedia({
			type: type,
			url: url,
			name: name,
			description: description
		});
		setMediaSave(true);
		setMediaEdit(false);
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (events.thumbImage === thumbImage) {
			dispatch(
				updateEvents({
					_id: eventId,
					name,
					result,
					thumbImage,
					thumbVid: thumbVideo,
					setTime: time,
					eventLink,
					media: media.url === null || media.url === '' || media === {} ? null : media,
					description,
				})
			);
		} else {
			dispatch(
				updateEvents({
					_id: eventId,
					name,
					result,
					thumbImage,
					thumbVid: thumbVideo,
					setTime: time,
					eventLink,
					media: media.url === null || media.url === '' || media === {} ? null : media,
					description,
				})
			);
		}
	};

	const uploadVidHandler = async (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('video', file);
		setUploadingVid(true);

		if (file) {
			try {
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};

				const { data } = await axios.post(`${serverIp}/api/uploadVid/eventVid`, formData, config);
				setThumbVideo(data);
				setUploadingVid(false);
			} catch (err) {
				console.error(err);
				setUploadingVid(false);
			}
		} else {
			setUploadingVid(false);
		}
	};

	const deleteVidHandler = async (e) => {
		e.preventDefault();
		setThumbVideo('');
	}
	const handleMediaObjectDelete = async(e) =>{
		e.preventDefault();
		setMedia({ url: null, name: '', type: '', description: '' });
		// setMediaSave(false);
	}
	const handleMediaObjectEdit = async(e) => {
		e.preventDefault();
		setMediaEdit(true);
	}

	return (
		<Flex direction='column' mt='5px'>

			<Flex w='full' alignItems='center' justifyContent='center' px={{ sm: '4px', md: '' }}>
				<FormContainer>
					<Heading as='h1' fontSize='3xl' color='white'>
						{t("Edit Event")}
					</Heading>

					{loadingUpdate && <Loader />}
					{errorUpdate && <Message type='error'>{errorUpdate}</Message>}

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						//onSubmit={submitHandler}
						<form onSubmit={submitHandler}>
							<Flex direction={{ sm: 'column', md: 'row' }} flexWrap={{ sm: 'nowrap', md: 'wrap' }} rowGap={{ sm: '10px', md: '15px' }} columnGap={{ sm: '0', md: '10px' }}>
								{/* NAME */}
								<FormControl id='name' isRequired width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white' >{t("Name")}</FormLabel>
									<Input
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter name")}
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</FormControl>

								{/* Set Result */}
								<FormControl id='result' isRequired width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white' >{t("Result")}</FormLabel>
									<Input
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter result")}
										value={result}
										onChange={(e) => setResult(e.target.value)}
									/>
								</FormControl>

								{/* Thumb IMAGE */}
								<FormControl id='thumbimage' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Cover Image")}</FormLabel>
									<Flex justifyContent='center'>
										<Flex>
										<ImageCropper route='uploads/eventImg' setIsShown={setIsShown} thumbImage={thumbImage} setThumbImage={setThumbImage} />
										</Flex>
									</Flex>
									{uploadingImg && <Loader />}
								</FormControl>

								{/* Video */}
								<FormControl id='video'  width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Introduction Video")}</FormLabel>
									<Flex as={AspectRatio} ratio={16 / 9} display={thumbVideo !== '' ? 'flex' : 'none'} justifyContent='center'>
										<video src={thumbVideo} controls />
									</Flex>
									<Flex gap='3' justifyContent='center'>
										<Input
											display={thumbVideo === '' ? 'flex' : 'none'}
											pt='4px'
											color='white'
											borderColor='#f6ebd9'
											type='file'
											accept='video/*'
											id='video-file'
											onChange={uploadVidHandler}
										/>
										<Button
											marginTop='10px'
											colorScheme='red'
											color='white'
											display={thumbVideo === '' ? 'none' : 'flex'}
											onClick={deleteVidHandler}
										>
											{t("Remove")}
										</Button>
									</Flex>
									{uploadingVid && <Loader />}
								</FormControl>

								{/* Set Time */}
								<FormControl id='setTime' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white' >{t("Set Date-Time")}</FormLabel>
									<Input
										color='white'
										css={`::-webkit-calendar-picker-indicator {
									filter: invert(1);
									}`
										}
										type='datetime-local'
										borderColor='white'
										placeholder={t("Enter date time")}
										min={`${new Date().toISOString().split(':')[0]}:${new Date().toISOString().split(':')[1]}`}
										value={time}
										onChange={(e) => setTime(e.target.value)}
									/>
								</FormControl>

								{/* event link */}
								<FormControl id='eventlink' isRequired width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Event Link")}</FormLabel>
									<Input
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter Event Link")}
										value={eventLink}
										onChange={(e) => setEventLink(e.target.value)}
									/>
								</FormControl>

								{/* DESCRIPTION */}
								<FormControl id='description' isRequired width={{ sm: 'full', md: '99%' }}>
									<FormLabel color='white'>{t("Description")}</FormLabel>
									<Input
										as={Textarea}
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter description")}
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
								</FormControl>

								{/* Additional Media */}
								<FormControl id='media' width={{ sm: 'full', md: '99%' }} color='white'>
									<FormLabel color='white'>{t("Additional Media")}</FormLabel>
									<Flex display={media.url == null ? 'flex' : 'none'}>
										<UploadFile media={media} onMediaChange={onMediaChange} onMediaEdit={onMediaEdit} onMediaSave={onMediaSave} mediaEdit={mediaEdit} mediaFor="event" />
									</Flex>
									<Flex display={media.url != null ? 'flex' : 'none'}>

										<Card maxW='sm' bgColor='white'>
											<CardBody>
												<Flex display={media.type === 'image' ? 'flex' : 'none'}>
													<Image
														src={media.url}
														alt={media.name}
														borderRadius='lg'
													/>
												</Flex>

												<Flex display={media.type === 'video' ? 'flex' : 'none'}>
													<video src={media.url} controls />
												</Flex>
												<Text color='black' fontSize='xl' fontWeight='bold'>
													{media.name}
												</Text>
												<Text color='black'>
													{media.description}
												</Text>
											</CardBody>
											<Divider />
											<CardFooter>
												<ButtonGroup spacing='2'>
													<Button variant='solid' colorScheme='blue' onClick={handleMediaObjectEdit}>
														Edit
													</Button>
													<Button variant='ghost' colorScheme='blue' onClick={handleMediaObjectDelete}>
														Delete
													</Button>
												</ButtonGroup>
											</CardFooter>
										</Card>

									</Flex>
								</FormControl>

								<Flex gap='5' mt='6'>
									<Button
										colorScheme='teal'
										color='white'
										type='submit'
										isLoading={loading}>
										{t("Done")}
									</Button>
									<AlertDialogExample />
								</Flex>
							</Flex>
						</form>
					)}
				</FormContainer>
			</Flex>
		</Flex>
	)
}

export default EventEditScreen;