import axios from 'axios';

import {
    EVENTS_LIST_FAIL,
    EVENTS_LIST_REQUEST,
    EVENTS_LIST_SUCCESS,
	EVENTS_DETAILS_FAIL,
	EVENTS_DETAILS_REQUEST,
	EVENTS_DETAILS_SUCCESS,
	EVENTS_CREATE_FAIL,
	EVENTS_CREATE_REQUEST,
	EVENTS_CREATE_SUCCESS,
	EVENTS_DELETE_FAIL,
	EVENTS_DELETE_REQUEST,
	EVENTS_DELETE_SUCCESS,
	EVENTS_UPDATE_FAIL,
	EVENTS_UPDATE_REQUEST,
	EVENTS_UPDATE_SUCCESS,
	PROG_EVENTS_LIST_FAIL,
	PROG_EVENTS_LIST_REQUEST,
	PROG_EVENTS_LIST_SUCCESS,
	EVENTS_COPY_FAIL,
	EVENTS_COPY_REQUEST,
	EVENTS_COPY_SUCCESS,
	ALL_PROG_EVENTS_LIST_FAIL,
	ALL_PROG_EVENTS_LIST_REQUEST,
	ALL_PROG_EVENTS_LIST_SUCCESS
} from '../constants/EventsConstants';
import serverIp from '../config/config';

export const listEvents = () => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/events`, config);

		dispatch({ type: EVENTS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: EVENTS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listProgEvents = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROG_EVENTS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs/${id}/events`, config);

		dispatch({ type: PROG_EVENTS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROG_EVENTS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listAllProgEvents = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ALL_PROG_EVENTS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs/events`, config);

		dispatch({ type: ALL_PROG_EVENTS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ALL_PROG_EVENTS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listEventDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/events/${id}`, config);

		dispatch({ type: EVENTS_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: EVENTS_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const createEvents = (event) => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_CREATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/coach/events`, event, config);

		dispatch({ type: EVENTS_CREATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: EVENTS_CREATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const copyEvents = (event) => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_COPY_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/coach/programs/copyEvents`, event, config);

		dispatch({ type: EVENTS_COPY_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: EVENTS_COPY_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateEvents = (event) => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/coach/events/${event._id}`,
			event,
			config
		);

		dispatch({ type: EVENTS_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: EVENTS_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deleteEvents = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: EVENTS_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/coach/events/${id}`, config);

		dispatch({ type: EVENTS_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: EVENTS_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};