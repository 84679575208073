export const EVENTS_LIST_REQUEST = 'EVENTS_LIST_REQUEST';
export const EVENTS_LIST_SUCCESS = 'EVENTS_LIST_SUCCESS';
export const EVENTS_LIST_FAIL = 'EVENTS_LIST_FAIL';

export const PROG_EVENTS_LIST_REQUEST = 'PROG_EVENTS_LIST_REQUEST';
export const PROG_EVENTS_LIST_SUCCESS = 'PROG_EVENTS_LIST_SUCCESS';
export const PROG_EVENTS_LIST_FAIL = 'PROG_EVENTS_LIST_FAIL';

export const ALL_PROG_EVENTS_LIST_REQUEST = 'ALL_PROG_EVENTS_LIST_REQUEST';
export const ALL_PROG_EVENTS_LIST_SUCCESS = 'ALL_PROG_EVENTS_LIST_SUCCESS';
export const ALL_PROG_EVENTS_LIST_FAIL = 'ALL_PROG_EVENTS_LIST_FAIL';

export const EVENTS_DETAILS_REQUEST = 'EVENTS_DETAILS_REQUEST';
export const EVENTS_DETAILS_SUCCESS = 'EVENTS_DETAILS_SUCCESS';
export const EVENTS_DETAILS_FAIL = 'EVENTS_DETAILS_FAIL';

export const EVENTS_CREATE_REQUEST = 'EVENTS_CREATE_REQUEST';
export const EVENTS_CREATE_SUCCESS = 'EVENTS_CREATE_SUCCESS';
export const EVENTS_CREATE_FAIL = 'EVENTS_CREATE_FAIL';
export const EVENTS_CREATE_RESET = 'EVENTS_CREATE_RESET';

export const EVENTS_COPY_REQUEST = 'EVENTS_COPY_REQUEST';
export const EVENTS_COPY_SUCCESS = 'EVENTS_COPY_SUCCESS';
export const EVENTS_COPY_FAIL = 'EVENTS_COPY_FAIL';
export const EVENTS_COPY_RESET = 'EVENTS_COPY_RESET';

export const EVENTS_DELETE_REQUEST = 'EVENTS_DELETE_REQUEST';
export const EVENTS_DELETE_SUCCESS = 'EVENTS_DELETE_SUCCESS';
export const EVENTS_DELETE_FAIL = 'EVENTS_DELETE_FAIL';

export const EVENTS_UPDATE_REQUEST = 'EVENTS_UPDATE_REQUEST';
export const EVENTS_UPDATE_SUCCESS = 'EVENTS_UPDATE_SUCCESS';
export const EVENTS_UPDATE_FAIL = 'EVENTS_UPDATE_FAIL';
export const EVENTS_UPDATE_RESET = 'EVENTS_UPDATE_RESET';