import axios from "axios";
import { 
    SUPPORT_DETAILS_FAIL,
    SUPPORT_DETAILS_REQUEST,
    SUPPORT_DETAILS_SUCCESS,
    ABOUTUS_DETAILS_FAIL,
    ABOUTUS_DETAILS_REQUEST,
    ABOUTUS_DETAILS_SUCCESS,
	SUPPORT_UPDATE_FAIL,
	SUPPORT_UPDATE_REQUEST,
	SUPPORT_UPDATE_SUCCESS,
	ABOUTUS_UPDATE_FAIL,
	ABOUTUS_UPDATE_REQUEST,
	ABOUTUS_UPDATE_SUCCESS,
 } from "../constants/infoConstants";
 import serverIp from "../config/config";

 export const supportDetails = () => async (dispatch, getState) => {
	try {
		dispatch({ type: SUPPORT_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/support`, config);

		dispatch({ type: SUPPORT_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: SUPPORT_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const aboutusDetails = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ABOUTUS_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/aboutUs`, config);

		dispatch({ type: ABOUTUS_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ABOUTUS_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateSupport = (support) => async (dispatch, getState) => {
	try {
		dispatch({ type: SUPPORT_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/coach/support/edit`,
			support,
			config
		);

		dispatch({ type: SUPPORT_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: SUPPORT_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateAboutUs = (aboutUs) => async (dispatch, getState) => {
	try {
		dispatch({ type: ABOUTUS_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/coach/aboutUs/edit`,
			aboutUs,
			config
		);

		dispatch({ type: ABOUTUS_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ABOUTUS_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};