import {
    DOUBTS_DETAILS_FAIL,
    DOUBTS_DETAILS_REQUEST,
    DOUBTS_DETAILS_SUCCESS
} from '../constants/doubtConstants';

export const doubtsDetailsReducer = (state = { doubts: { doubt:{}, userName:'' } }, action) => {
	switch (action.type) {
		case DOUBTS_DETAILS_REQUEST:
			return { ...state, loading: true };
		case DOUBTS_DETAILS_SUCCESS:
			return { loading: false, doubts: action.payload };
		case DOUBTS_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};