import { Flex, Icon, Button, Heading, Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listPrograms } from '../actions/programsActions';
import ProgramsListCard from '../components/ProgramsListCard';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const ProgramsListScreen = () => {

	const dispatch = useDispatch();

	const [category, setCategoryFilter] = useState("all");
	const [liveStatus, setLiveFilter] = useState(true);

	const programsList = useSelector((state) => state.programsList);
	const { loading, error, programs } = programsList;

	const programsDelete = useSelector((state) => state.programsDelete);
	const {
		success: successDelete,
	} = programsDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listPrograms(category, liveStatus));
	}, [dispatch, successDelete, category, liveStatus]);

	return (
		<Flex direction='column' w="full">
			<Flex h='full' direction='column' w={{ sm: 'full', md: 'full' }}>
				<Flex py='2' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Button bgColor='gray.200' color='black' as={RouterLink} to={'/programs/add'}>
						<Icon as={IoAddCircleOutline} boxSize='5' />
						{t("Add Program")}
					</Button>
				</Flex>

				<Flex alignItems="center" justifyContent="space-between" marginBottom="10px" px={{ sm: '5px', md: '66px' }}>
					<Heading fontSize="24px">Filter</Heading>
					<Flex alignItems="center">
						<Select
							onChange={(e) => setCategoryFilter(e.target.value)}
							value={category}
							marginRight="8px"
						>
							<option value="all">{t("All")}</option>
							<option value="sales">{t("Sales")}</option>
							<option value="marketing">{t("Marketing")}</option>
							<option value="performance">{t("Performance")}</option>
							<option value="mindset">{t("Mindset")}</option>
							<option value="free">{t("Free")}</option>
						</Select>
						<Select
							onChange={(e) => setLiveFilter(e.target.value)}
							value={liveStatus}
						>
							<option value="true">Live</option>
							<option value="false">Offline</option>
						</Select>
					</Flex>
				</Flex>

				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Flex pt='5px' direction={{ sm: 'column', md: 'row' }} gap='2' flexWrap='wrap' px={{ sm: '5px', md: '66px' }}>
						{programs.programme.map((program) => (
							<ProgramsListCard key={program._id} program={program} />
						))}
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default ProgramsListScreen;