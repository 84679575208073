import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button
  } from '@chakra-ui/react'
  import { useDisclosure } from '@chakra-ui/react'
  import { useRef, useEffect } from 'react'
  import { useNavigate } from 'react-router-dom'
  import { useTranslation } from 'react-i18next';
  
  function AlertDialogExample() {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const { t } = useTranslation();
  
    const handleCancel = () => {
      onClose();
      navigate(-1); // navigate to the previous page
    }
  
    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = '';
          onOpen();
        }
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        }
      }, [onOpen]);
  
    return (
      <>
        <Button colorScheme='red' onClick={onOpen}>
          Cancel
        </Button>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {t("are_you_sure")}
              </AlertDialogHeader>
  
              <AlertDialogBody>
                {t("leaving_page_warning")}
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  {t("stay")}
                </Button>
                <Button colorScheme='red' onClick={handleCancel} ml={3}>
                  {t("leave")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }
  
  export default AlertDialogExample;
  