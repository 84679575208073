import "./messenger.css";
import Conversation from "../../components/conversations/Conversation";
import Message from "../../components/message/Message";
import ChatOnline from "../../components/chatOnline/ChatOnline";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import serverIp from "../../config/config";
import { listUsers } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

export default function Messenger() {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [seenMessage, setSeenMessage] = useState(null);
  const [refreshChat, setRefreshChat] = useState(false);
  const socket = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate
  // const { user } = useContext(AuthContext);
  const scrollRef = useRef();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo: user } = userLogin;

  const convDelete = useSelector((state) => state.convDelete);
  const {
    success: successDelete,
  } = convDelete;

  useEffect(() => {
    if (user && user.isCoach) {
      dispatch(listUsers());
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, user]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get(`${serverIp}/api/conversations/` + user._id);
        setConversations(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [user._id, currentChat, successDelete, messages, arrivalMessage]);

  useEffect(() => {
    socket.current = io("ws://localhost:5000").connect();
    socket.current.emit("addUser", user._id);
    socket.current.on("getUsers", (users) => {
      
    });
  }, [user]);

  useEffect(() => {
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        _id: data.messageId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

   useEffect(() => {
      socket.current.on("getSeenStatus", (data) => {
        setSeenMessage(data);
      })
  }, [])

  useEffect(()=>{
    socket.current.on("refreshChat", (data) => {
      setRefreshChat(true);
    })
  })

  useEffect(() => {
    arrivalMessage &&
    currentChat?.members.some((m) => m.id === arrivalMessage.sender) &&
      setMessages([...messages, {
        "_id": arrivalMessage._id,
        "conversationId": currentChat._id,
        "sender": arrivalMessage.sender,
        "text": arrivalMessage.text,
        "seen": false
      }]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(`${serverIp}/api/messages/` + currentChat?._id);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
    setRefreshChat(false);
  }, [user._id, currentChat, successDelete, refreshChat]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    messages && sendSeenStatus();
  }, [messages]);

  const generate = () => {
    let timestamp = Math.floor(Date.now() / 1000).toString(16);
    let randomHex = Array(16).fill(null).map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    return timestamp + randomHex;
  }

  const sendSeenStatus = () => {
    messages &&
    messages.forEach(message => {
      if(message.sender !== user._id && message.seen === false) {
        socket.current.emit("seenStatus", {
          senderId: message.sender,
          messageId: message._id,
          seenStatus: true
        })
      }
    });
      
  }

  const updateAllSeenStatus = (e, c) => {
    e.preventDefault();
    socket.current.emit("markAllSeen", {
      userId: user._id,
      conversationId: c._id
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let meId = generate();

    const message = {
      _id: meId,
      sender: user._id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member.id !== user._id
    );

    socket.current.emit("sendMessage", {
      conversationId: currentChat._id,
      senderId: user._id,
      senderName: user.name,
      receiverId: receiverId.id,
      messageId: message._id,
      text: newMessage,
    });

    setMessages([...messages, {
      "_id": message._id,
      "conversationId": currentChat._id,
      "sender": user._id,
      "text": newMessage,
      "seen": false
    }]);

    setNewMessage("");
  };

  return (
    <>
      <Flex h='full' direction='column' w={{ md: 'full' }}>
        <Flex py='4' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
          <Heading fontSize='xl' as={RouterLink} to='/home' color='white'>
            {("Back")}
          </Heading>
        </Flex>
      </Flex>
      {/* <Topbar /> */}
      <div className="messenger">
        <div className="chatMenu">
          <div className="chatMenuWrapper">
            {/* <input placeholder="Search for friends" className="chatMenuInput" /> */}
            <div>
              <Text fontSize='large' fontWeight='bold' textAlign='center' borderBottom='1px solid gray' paddingBottom='7px'>Chats</Text>
            </div>
            {conversations.map((c) => (
              <div onClick={(e) => {
                setCurrentChat(c);
                updateAllSeenStatus(e, c);
                }}>
                <Conversation conversation={c} currentUser={user} />
              </div>
            ))}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentChat ? (
              <>
                <div>
                  {currentChat.members.map((member) => (
                    <div>
                      {member.id !== user._id ? (
                        <>
                          <div>
                            <Text fontSize='large' fontWeight='bold' textAlign='center' borderBottom='1px solid gray' paddingBottom='7px'>{member.name}</Text>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                      }
                    </div>
                  ))}
                </div>
                <div className="chatBoxTop">
                  {messages.map((m) => (
                    <div ref={scrollRef}>
                      <Message message={m} own={m.sender === user._id} seenMessage={seenMessage} />
                    </div>
                  ))}
                </div>
                <div className="chatBoxBottom">
                  <textarea
                    className="chatMessageInput"
                    placeholder="write something..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  ></textarea>
                  <button className="chatSubmitButton" onClick={handleSubmit}>
                    Send
                  </button>
                </div>
              </>
            ) : (
              <span className="noConversationText">
                Open a conversation to start a chat.
              </span>
            )}
          </div>
        </div>
        <div className="chatOnline">
          <div className="chatOnlineWrapper">
            <div>
              <Text fontSize='large' fontWeight='bold' textAlign='center' borderBottom='1px solid gray' paddingBottom='7px'>Start New Chat</Text>
            </div>
            {loading ? (
              <Loader />
            ) : error ? (
              <errMessage />
            ) : (
              <ChatOnline
                onlineUsers={users.users}
                currentId={user._id}
                setCurrentChat={setCurrentChat}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}