export const SUPPORT_DETAILS_REQUEST = 'SUPPORT_DETAILS_REQUEST';
export const SUPPORT_DETAILS_SUCCESS = 'SUPPORT_DETAILS_SUCCESS';
export const SUPPORT_DETAILS_FAIL = 'SUPPORT_DETAILS_FAIL';

export const ABOUTUS_DETAILS_REQUEST = 'ABOUTUS_DETAILS_REQUEST';
export const ABOUTUS_DETAILS_SUCCESS = 'ABOUTUS_DETAILS_SUCCESS';
export const ABOUTUS_DETAILS_FAIL = 'ABOUTUS_DETAILS_FAIL';

export const SUPPORT_UPDATE_REQUEST = 'SUPPORT_UPDATE_REQUEST';
export const SUPPORT_UPDATE_SUCCESS = 'SUPPORT_UPDATE_SUCCESS';
export const SUPPORT_UPDATE_FAIL = 'SUPPORT_UPDATE_FAIL';
export const SUPPORT_UPDATE_RESET = 'SUPPORT_UPDATE_RESET';

export const ABOUTUS_UPDATE_REQUEST = 'ABOUTUS_UPDATE_REQUEST';
export const ABOUTUS_UPDATE_SUCCESS = 'ABOUTUS_UPDATE_SUCCESS';
export const ABOUTUS_UPDATE_FAIL = 'ABOUTUS_UPDATE_FAIL';
export const ABOUTUS_UPDATE_RESET = 'ABOUTUS_UPDATE_RESET';