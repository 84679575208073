import { initializeApp } from "firebase/app";
import { getMessaging  } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBZw4kRYJXZJVDiVg3U9jGBnvBdiziuE8Q",
  authDomain: "coach-app-85759.firebaseapp.com",
  projectId: "coach-app-85759",
  storageBucket: "coach-app-85759.appspot.com",
  messagingSenderId: "559027872731",
  appId: "1:559027872731:web:2e94f444afe18181bdbdfe",
  measurementId: "G-X54D99TZCF"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);