import { Button, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {TbTrash} from 'react-icons/tb';
import "./conversation.css";

import { deleteChats } from "../../actions/chatActions";

export default function Conversation({ conversation, currentUser }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [img, setImg] = useState();
  const [lastMessage, setLastMessage] = useState();
  const [unseenCount, setUnseenCount] = useState(0);

  useEffect(() => {
    const receiver = conversation.members.find((m) => m.id !== currentUser._id);

    setUser(receiver.name);
    setImg(receiver.img);
    setLastMessage(conversation.lastMessage);
    setUnseenCount(conversation.unseenCount);
    if(unseenCount !== 0){
      document.getElementById("unseenCount").style.display = 'flex';
    } else if (unseenCount === 0) {
      document.getElementById("unseenCount").style.display = 'none';
    }
  }, [currentUser, conversation, unseenCount]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteChats(id));
    }
    conversation = null;
  };

  return (
    <div className="conv">
      <div className="conversation">
        <img
          className="conversationImg"
          src={img}
          alt=""
        />
        <div className="conn">
          <div>
          <span className="conversationName">{user}</span>
          <p>{lastMessage}</p>
          </div>
          <div className="noti" id="unseenCount">
            <Text alignSelf='center' color='white' fontWeight='bold'>{unseenCount}</Text>
          </div>
        </div>
      </div>
      <Button alignSelf='center' bg='none' w={{ sm: '5', lg: '3' }} h={{ sm: '5', lg: '8' }} type='button' onClick={() => deleteHandler(conversation._id)}>
        <Icon as={TbTrash} boxSize={{ sm: '5', lg: '6' }} fontSize='xl' fontWeight='bold' />
      </Button>
    </div>
  );
}