import {
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	BG_IMAGE_FAIL,
	BG_IMAGE_REQUEST,
	BG_IMAGE_SUCCESS,
	BG_IMAGE_UPDATE_FAIL,
	BG_IMAGE_UPDATE_REQUEST,
	BG_IMAGE_UPDATE_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_RESET,
	USER_LIST_SUCCESS,
	USER_DELETE_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_RESET,
	USER_DOUBT_LIST_FAIL,
	USER_DOUBT_LIST_REQUEST,
	USER_DOUBT_LIST_RESET,
	USER_DOUBT_LIST_SUCCESS,
	USER_LIST_DETAILS_FAIL,
	USER_LIST_DETAILS_REQUEST,
	USER_LIST_DETAILS_RESET,
	USER_LIST_DETAILS_SUCCESS,
	FEEDBACK_USER_LIST_FAIL,
	FEEDBACK_USER_LIST_REQUEST,
	FEEDBACK_USER_LIST_RESET,
	FEEDBACK_USER_LIST_SUCCESS,
	FEEDBACK_FAIL,
	FEEDBACK_REQUEST,
	FEEDBACK_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_RESET,
	USER_DETAILS_SUCCESS,
	USER_UPDATE_PROFILE_FAIL,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_RESET,
	USER_UPDATE_PROFILE_SUCCESS,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };
		case USER_LOGIN_SUCCESS:
			return { loading: false, userInfo: action.payload };
		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };
		case USER_LOGOUT:
			return {};
		default:
			return state;
	}
};

export const bgImageReducer = (state = {}, action) => {
	switch (action.type) {
		case BG_IMAGE_REQUEST:
			return { loading: true };
		case BG_IMAGE_SUCCESS:
			return { loading: false, imgInfo: action.payload };
		case BG_IMAGE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const bgImageUpdateReducer = (state = { bgImage:{} }, action) => {
	switch (action.type) {
		case BG_IMAGE_UPDATE_REQUEST:
			return { loading: true, ...state };
		case BG_IMAGE_UPDATE_SUCCESS:
			return { loading: false, bgImage: action.payload };
		case BG_IMAGE_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const userListReducer = (state = { users: {users:[]} }, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true };
		case USER_LIST_SUCCESS:
			return { loading: false, users: action.payload };
		case USER_LIST_FAIL:
			return { loading: false, error: action.payload };
		case USER_LIST_RESET:
			return { users: [] };
		default:
			return state;
	}
};

export const userDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_DELETE_REQUEST:
			return { loading: true };
		case USER_DELETE_SUCCESS:
			return { loading: false, success: true };
		case USER_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const userUpdateReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_REQUEST:
			return { ...state, loading: true };
		case USER_UPDATE_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case USER_UPDATE_RESET:
			return { user: {} };
		default:
			return state;
	}
};

export const userDoubtListReducer = (state = { userDoubt:{userDoubtList:[]} }, action) => {
	switch (action.type) {
		case USER_DOUBT_LIST_REQUEST:
			return { loading: true };
		case USER_DOUBT_LIST_SUCCESS:
			return { loading: false, userDoubt: action.payload };
		case USER_DOUBT_LIST_FAIL:
			return { loading: false, error: action.payload };
		case USER_DOUBT_LIST_RESET:
			return { userDoubtList: [] };
		default:
			return state;
	}
};

export const userListDetailsReducer = (state = { user: { user:{} } }, action) => {
	switch (action.type) {
		case USER_LIST_DETAILS_REQUEST:
			return { ...state, loading: true };
		case USER_LIST_DETAILS_SUCCESS:
			return { loading: false, user: action.payload };
		case USER_LIST_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		case USER_LIST_DETAILS_RESET:
			return { user: {} };
		default:
			return state;
	}
};

export const feedbackUserListReducer = (state = { users: {users:[]} }, action) => {
	switch (action.type) {
		case FEEDBACK_USER_LIST_REQUEST:
			return { loading: true };
		case FEEDBACK_USER_LIST_SUCCESS:
			return { loading: false, users: action.payload };
		case FEEDBACK_USER_LIST_FAIL:
			return { loading: false, error: action.payload };
		case FEEDBACK_USER_LIST_RESET:
			return { users: [] };
		default:
			return state;
	}
};

export const feedbackDetailsReducer = (state = { feedback: {} }, action) => {
	switch (action.type) {
		case FEEDBACK_REQUEST:
			return { ...state, loading: true };
		case FEEDBACK_SUCCESS:
			return { loading: false, feedback: action.payload };
		case FEEDBACK_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const userDetailsReducer = (state = { user:{ user: {} }}, action) => {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { ...state, loading: true };
		case USER_DETAILS_SUCCESS:
			return { loading: false, user: action.payload };
		case USER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		case USER_DETAILS_RESET:
			return { user: { user:{}} };
		default:
			return state;
	}
};

export const userUpdateProfileReducer = (state = { user: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_PROFILE_REQUEST:
			return { loading: true };
		case USER_UPDATE_PROFILE_SUCCESS:
			return { loading: false, user: action.payload, success: true };
		case USER_UPDATE_PROFILE_FAIL:
			return { loading: false, error: action.payload };
		case USER_UPDATE_PROFILE_RESET:
			return { user: {} };
		default:
			return state;
	}
};