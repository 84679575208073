import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Box, Image, Button, Center, Input } from '@chakra-ui/react';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import { getCroppedImg } from './cropImage';
import serverIp from '../config/config';

const ImageCropper = (props) => {
    const [image, setImage] = useState('');
    const [croppedImage, setCroppedImage] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(0.5);
    const aspectRatio = 16 / 9;
    const [showInput, setShowInput] = useState(true);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setImage(reader.result);
            setShowInput(false);
            setShowUploadButton(true);
        };

        reader.readAsDataURL(file);
    }, []);

    useEffect(() => {
        function setStates(){
            setCroppedImage(props.thumbImage)
            setShowRemoveButton(true);
            setShowUploadButton(false);
            setShowInput(false);
        }
        props.thumbImage !== '' && setStates()
    },[props.thumbImage])

    const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        if (image && croppedAreaPixels) {
          return new Promise((resolve, reject) => {
            const croppedImg = getCroppedImg(image, croppedAreaPixels);
            if (croppedImg) {
              resolve(croppedImg);
            } else {
              reject(new Error('Failed to crop image'));
            }
          }).then((croppedImg) => {
            setCroppedImage(croppedImg);
            setShowInput(false);
          });
        }
      }, [image]);

    const handleZoomChange = useCallback((zoom) => {
        setZoom(zoom);
    }, []);

    const handleRemoveButtonClick = () => {
        setShowRemoveButton(false);
        props.setThumbImage('');
        setShowInput(true)
        setCroppedImage('');
    }

    const handleUploadButtonClick = async() => {
        const formData = new FormData();
        formData.append('image', croppedImage);
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const { data } = await axios.post(`${serverIp}/api/${props.route}`, formData, config);
            setCroppedImage(data);
            props.setIsShown(false);
            props.setThumbImage(data);
            setShowRemoveButton(true);
            setShowUploadButton(false);
            setImage(null)
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Flex flexDirection='column' alignItems="center">
            <Box>
                {showInput ? (
                    <Center>
                        <Input type="file" pt='4px' onChange={handleFileChange} accept="image/*"/>
                    </Center>
                ) : image ? (
                    <Flex w='full' h='300px'>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio}
                        onCropChange={setCrop}
                        onZoomChange={handleZoomChange}
                        onCropComplete={handleCropComplete}
                    />
                    </Flex>
                ) : (
                    <></>
                )}
            </Box>
            {showRemoveButton && (
                <Box ml={[0, 4]} mt={[4, 0]}>
                    <Image src={croppedImage} />
                    <Box mt="4" display='flex' justifyContent='center'>
                        <Button colorScheme='red' onClick={handleRemoveButtonClick}>Remove</Button>
                    </Box>
                </Box>
            )}
            {showUploadButton && (
                <Box display='flex' justifyContent='center'>
                    <Button colorScheme='teal' onClick={handleUploadButtonClick}>Upload</Button>
                </Box>
            )}
        </Flex>
    );
}

export default ImageCropper;
