import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Text,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoTrashBinSharp,
	IoAddCircleOutline,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProgTasks, deleteTasks } from '../actions/tasksActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { TASKS_COPY_RESET } from '../constants/tasksConstants';

const ProgTasksListScreen = () => {
	const dispatch = useDispatch();
	const { id: Progid } = useParams();

	const progTasksList = useSelector((state) => state.progTasksList);
	const { loading, error, tasks } = progTasksList;

	const tasksDelete = useSelector((state) => state.tasksDelete);
	const {
		success: successDelete,
	} = tasksDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listProgTasks(Progid));
		dispatch({ type: TASKS_COPY_RESET });
	}, [dispatch, successDelete, Progid]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteTasks(id));
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='2' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' color='white' as={RouterLink} to={`/programs/${Progid}`} alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Program")}
					</Flex>
					<Flex gap='2'>
						<Button bgColor='gray.200' color='black' as={RouterLink} to={`/programs/${Progid}/tasks/copy`}>
							<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Copy Tasks")}
						</Button>
						<Button bgColor='gray.200' color='black' as={RouterLink} to={`/programs/${Progid}/tasks/add`}>
							<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Add Task")}
						</Button>
					</Flex>
				</Flex>
				<Flex>
					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{ sm: '390px', md: 'full' }}>
							<Table variant='striped' colorScheme='gray' size='sm'>
								<Thead>
									<Tr>
										<Th>{t("NAME")}</Th>
										<Th>
											<Flex justifyContent='flex-end'>
												<Text>{t("DELETE")}</Text>
											</Flex>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{tasks.updatedTasks.map((obj) => (
										<Tr key={obj._id}>
											<Td>
												<Flex as={RouterLink} to={`/tasks/${obj._id}`}>
													{obj.title}
												</Flex>
											</Td>

											<Td>
												<Flex justifyContent='flex-end' alignItems='center' gap='4'>
													<Button size={{ sm: 'sm', lg: 'md' }} colorScheme='red' onClick={() => deleteHandler(obj._id)} >
														<Icon as={IoTrashBinSharp} />
													</Button>
												</Flex>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</Box>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ProgTasksListScreen;
