import axios from 'axios';

import {
    TASKS_LIST_FAIL,
    TASKS_LIST_REQUEST,
    TASKS_LIST_SUCCESS,
	TASKS_DETAILS_FAIL,
	TASKS_DETAILS_REQUEST,
	TASKS_DETAILS_SUCCESS,
	TASKS_CREATE_FAIL,
	TASKS_CREATE_REQUEST,
	TASKS_CREATE_SUCCESS,
	TASKS_DELETE_FAIL,
	TASKS_DELETE_REQUEST,
	TASKS_DELETE_SUCCESS,
	TASKS_UPDATE_FAIL,
	TASKS_UPDATE_REQUEST,
	TASKS_UPDATE_SUCCESS,
	TASKS_ASSIGN_FAIL,
	TASKS_ASSIGN_REQUEST,
	TASKS_ASSIGN_SUCCESS,
	USER_TASKS_LIST_FAIL,
	USER_TASKS_LIST_REQUEST,
	USER_TASKS_LIST_SUCCESS,
	PROG_TASKS_LIST_FAIL,
	PROG_TASKS_LIST_REQUEST,
	PROG_TASKS_LIST_SUCCESS,
	TASKS_COPY_FAIL,
	TASKS_COPY_REQUEST,
	TASKS_COPY_SUCCESS,
	ALL_PROG_TASKS_LIST_FAIL,
	ALL_PROG_TASKS_LIST_REQUEST,
	ALL_PROG_TASKS_LIST_SUCCESS
} from '../constants/tasksConstants';
import serverIp from '../config/config';

export const listTasks = () => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/tasks`, config);

		dispatch({ type: TASKS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: TASKS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listProgTasks = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROG_TASKS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs/${id}/tasks`, config);

		dispatch({ type: PROG_TASKS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROG_TASKS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listAllProgTasks = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ALL_PROG_TASKS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs/tasks`, config);

		dispatch({ type: ALL_PROG_TASKS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: ALL_PROG_TASKS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listTaskDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/tasks/${id}`, config);

		dispatch({ type: TASKS_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: TASKS_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const createTasks = (task) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_CREATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/coach/tasks`, task, config);

		dispatch({ type: TASKS_CREATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: TASKS_CREATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const copyTasks = (task) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_COPY_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/coach/programs/copyTasks`, task, config);

		dispatch({ type: TASKS_COPY_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: TASKS_COPY_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateTasks = (task) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/coach/tasks/${task._id}`,
			task,
			config
		);

		dispatch({ type: TASKS_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: TASKS_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deleteTasks = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/coach/tasks/${id}`, config);

		dispatch({ type: TASKS_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: TASKS_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const assignTasks = (id, setUsers) => async (dispatch, getState) => {
	try {
		dispatch({ type: TASKS_ASSIGN_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		let users = setUsers
		await axios.post(`${serverIp}/api/coach/tasks/${id}/assign`, { users }, config);

		dispatch({ type: TASKS_ASSIGN_SUCCESS });
	} catch (err) {
		dispatch({
			type: TASKS_ASSIGN_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listUserTasks = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_TASKS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/users/${id}/tasks`, config);

		dispatch({ type: USER_TASKS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: USER_TASKS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};