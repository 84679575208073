import axios from "axios";
import { CONV_DELETE_FAIL, CONV_DELETE_REQUEST, CONV_DELETE_SUCCESS } from "../constants/chatConstants";
import serverIp from "../config/config";

export const deleteChats = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: CONV_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/conversations/${id}`, config);

		dispatch({ type: CONV_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: CONV_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};