import "./message.css";
import { Text } from "@chakra-ui/react";
import { format } from "timeago.js";

export default function Message({ message, own }) {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <p className="messageText">{message.text}</p>
      </div>
      <div className="messageBottom">
        <Text fontSize='smaller' color='black'>{format(message.createdAt)}</Text>
       <Text color='blue'>{message.seen && own? " seen" : ""}</Text>
       </div>
    </div>
  );
}