import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Table,
	Image,
	Tbody,
	Text,
	Td,
	Th,
	Thead,
	Tr,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoCheckmarkCircleSharp,
	IoCloseCircleSharp,
	IoPencilSharp,
	IoTrashBinSharp,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { deleteUser, listUsers } from '../actions/userActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const UserListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	const { t } = useTranslation();

	useEffect(() => {
		if (userInfo && userInfo.isCoach) {
			dispatch(listUsers());
		} else {
			navigate('/login');
		}
	}, [dispatch, navigate, userInfo, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='4' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/home' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Heading fontSize='xl' color='white'>
						{t("Users")}
					</Heading>
				</Flex>
				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{ sm: '390px', md: 'full' }}>
						<Table variant='striped' colorScheme='gray' size='sm'>
							<Thead>
								<Tr>
									<Th>{t("IMAGE")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("NAME")}</Th>
									<Th display={{ sm: 'table-cell', md: 'none' }}>{t("Details")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("EMAIL")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("POSITION")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("ORGANIZATION")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("ASTROLOGY")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("IS COACH")}</Th>
									<Th>
										<Text pl='3'>{t("TASKS")}</Text>
									</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>
										<Flex justifyContent='space-evenly' mr='5' gap='20px'>
											<Text pl='2'>{t("EDIT")}</Text>
											<Text>{t("DELETE")}</Text>
										</Flex>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{users.users.map((user) => (
									<Tr key={user._id}>
										<Td>
											<Image src={user.image} h={{ sm: '35px', lg: '70px' }} w={{ sm: '35px', lg: '70px' }} />
										</Td>
										<Td>
											<Flex direction='column' gap='2px'>
												<Text fontSize='lg'>{user.name}</Text>
												<Text fontSize='sm' color='gray' display={{ sm: 'flex', md: 'none' }}>{user.position}</Text>
												<Text fontSize='sm' color='gray' display={{ sm: 'flex', md: 'none' }}>{user.orgName}</Text>
											</Flex>
										</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>
											<a href={`mailto:${user.email}`}>{user.email}</a>
										</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>{user.position}</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>{user.orgName}</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>
											{user.yogaNum ? (
												<Popover placement='top-start'>
													<PopoverTrigger>
														<Button colorScheme='teal'>{t("Yoga Numerology")}</Button>
													</PopoverTrigger>
													<PopoverContent>
														<PopoverHeader fontWeight='semibold'>{t("Yoga Numerology")}</PopoverHeader>
														<PopoverArrow />
														<PopoverCloseButton />
														<PopoverBody>
															<Text>{t("Soul Number")} {":"} {user.yogaNum.soulNum}</Text>
															<Text>{t("Karma Number")} {":"} {user.yogaNum.karmaNum}</Text>
															<Text>{t("Gift Number")} {":"} {user.yogaNum.giftNum}</Text>
															<Text>{t("Determination Number")} {":"} {user.yogaNum.determinationNum}</Text>
															<Text>{t("Way Number")} {":"} {user.yogaNum.pathNum}</Text>
														</PopoverBody>
													</PopoverContent>
												</Popover>
											) : (
												<Flex>
													N/A
												</Flex>
											)}
										</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>
											{user.isCoach ? (
												<Flex>
													<Icon
														as={IoCheckmarkCircleSharp}
														color='green.600'
														w='8'
														h='8'
													/>
												</Flex>
											) : (
												<Flex pl='3.5'>
													<Icon
														as={IoCloseCircleSharp}
														color='red.600'
														w='8'
														h='8'
													/>
												</Flex>
											)}
										</Td>
										<Td>
											<Button
												mr='4'
												as={RouterLink}
												to={`/users/${user._id}/tasks`}
												colorScheme='teal'>
												{t("Tasks")}
											</Button>
										</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>
											<Flex justifyContent='space-evenly' alignItems='center'>
												<Button
													mr='4'
													as={RouterLink}
													to={`/users/${user._id}/edit`}
													colorScheme='teal'>
													<Icon as={IoPencilSharp} color='white' />
												</Button>
												<Button
													mr='4'
													colorScheme='red'
													onClick={() => deleteHandler(user._id)}>
													<Icon as={IoTrashBinSharp} color='white' />
												</Button>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
			</Flex>
		</Flex>
	);
};

export default UserListScreen;
