import {
    PROGRAMS_LIST_FAIL,
    PROGRAMS_LIST_REQUEST,
    PROGRAMS_LIST_SUCCESS,
	PROGRAMS_DETAILS_FAIL,
	PROGRAMS_DETAILS_REQUEST,
	PROGRAMS_DETAILS_SUCCESS,
	PROGRAMS_CREATE_FAIL,
	PROGRAMS_CREATE_REQUEST,
	PROGRAMS_CREATE_RESET,
	PROGRAMS_CREATE_SUCCESS,
	PROGRAMS_DELETE_FAIL,
	PROGRAMS_DELETE_REQUEST,
	PROGRAMS_DELETE_SUCCESS,
	PROGRAMS_UPDATE_FAIL,
	PROGRAMS_UPDATE_REQUEST,
	PROGRAMS_UPDATE_RESET,
	PROGRAMS_UPDATE_SUCCESS,
	FEEDBACK_PROGRAMS_LIST_FAIL,
	FEEDBACK_PROGRAMS_LIST_REQUEST,
	FEEDBACK_PROGRAMS_LIST_SUCCESS,
} from '../constants/programsConstants';

export const programsListReducer = (state = { programs: { programme:[] } }, action) => {
	switch (action.type) {
		case PROGRAMS_LIST_REQUEST:
			return { loading: true, programs: { programme:[] } };
		case PROGRAMS_LIST_SUCCESS:
			return { loading: false, programs: action.payload };
		case PROGRAMS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const programsDetailsReducer = (state = { programs: { feedback: [], media:{} } }, action) => {
	switch (action.type) {
		case PROGRAMS_DETAILS_REQUEST:
			return { ...state, loading: true };
		case PROGRAMS_DETAILS_SUCCESS:
			return { loading: false, programs: action.payload };
		case PROGRAMS_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const programsCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case PROGRAMS_CREATE_REQUEST:
			return { loading: true };
		case PROGRAMS_CREATE_SUCCESS:
			return { loading: false, success: true, program: action.payload };
		case PROGRAMS_CREATE_FAIL:
			return { loading: false, error: action.payload };
		case PROGRAMS_CREATE_RESET:
			return {};
		default:
			return state;
	}
};

export const programsUpdateReducer = (state = { program: {} }, action) => {
	switch (action.type) {
		case PROGRAMS_UPDATE_REQUEST:
			return { ...state, loading: true };
		case PROGRAMS_UPDATE_SUCCESS:
			return { loading: false, success: true, program: action.payload };
		case PROGRAMS_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case PROGRAMS_UPDATE_RESET:
			return { program: {} };
		default:
			return state;
	}
};

export const programsDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case PROGRAMS_DELETE_REQUEST:
			return { loading: true };
		case PROGRAMS_DELETE_SUCCESS:
			return { loading: false, success: true };
		case PROGRAMS_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const feedbackProgramsListReducer = (state = { programs: { programme:[] } }, action) => {
	switch (action.type) {
		case FEEDBACK_PROGRAMS_LIST_REQUEST:
			return { loading: true, programs: { programme:[] } };
		case FEEDBACK_PROGRAMS_LIST_SUCCESS:
			return { loading: false, programs: action.payload };
		case FEEDBACK_PROGRAMS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};