import axios from 'axios';
import {
    DOUBTS_DETAILS_FAIL,
    DOUBTS_DETAILS_REQUEST,
    DOUBTS_DETAILS_SUCCESS
} from '../constants/doubtConstants';
import serverIp from '../config/config';

export const listDoubtDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DOUBTS_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/doubt/${id}`, config);

		dispatch({ type: DOUBTS_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: DOUBTS_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};