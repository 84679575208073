import {
    EVENTS_LIST_FAIL,
    EVENTS_LIST_REQUEST,
    EVENTS_LIST_SUCCESS,
	EVENTS_DETAILS_FAIL,
	EVENTS_DETAILS_REQUEST,
	EVENTS_DETAILS_SUCCESS,
	EVENTS_CREATE_FAIL,
	EVENTS_CREATE_REQUEST,
	EVENTS_CREATE_RESET,
	EVENTS_CREATE_SUCCESS,
	EVENTS_DELETE_FAIL,
	EVENTS_DELETE_REQUEST,
	EVENTS_DELETE_SUCCESS,
	EVENTS_UPDATE_FAIL,
	EVENTS_UPDATE_REQUEST,
	EVENTS_UPDATE_RESET,
	EVENTS_UPDATE_SUCCESS,
	PROG_EVENTS_LIST_FAIL,
	PROG_EVENTS_LIST_REQUEST,
	PROG_EVENTS_LIST_SUCCESS,
	EVENTS_COPY_FAIL,
	EVENTS_COPY_REQUEST,
	EVENTS_COPY_RESET,
	EVENTS_COPY_SUCCESS,
	ALL_PROG_EVENTS_LIST_FAIL,
	ALL_PROG_EVENTS_LIST_REQUEST,
	ALL_PROG_EVENTS_LIST_SUCCESS
} from '../constants/EventsConstants';

export const eventsListReducer = (state = { events: {event:[]} }, action) => {
	switch (action.type) {
		case EVENTS_LIST_REQUEST:
			return { loading: true, events:{event:[]}};
		case EVENTS_LIST_SUCCESS:
			return { loading: false, events: action.payload };
		case EVENTS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const progEventsListReducer = (state = { events: {event:[]} }, action) => {
	switch (action.type) {
		case PROG_EVENTS_LIST_REQUEST:
			return { loading: true, events:{event:[]}};
		case PROG_EVENTS_LIST_SUCCESS:
			return { loading: false, events: action.payload };
		case PROG_EVENTS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const allProgEventsListReducer = (state = { events: [] }, action) => {
	switch (action.type) {
		case ALL_PROG_EVENTS_LIST_REQUEST:
			return { loading: true, events: [] };
		case ALL_PROG_EVENTS_LIST_SUCCESS:
			return { loading: false, events: action.payload };
		case ALL_PROG_EVENTS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const eventsDetailsReducer = (state = { events: {} }, action) => {
	switch (action.type) {
		case EVENTS_DETAILS_REQUEST:
			return { ...state, loading: true };
		case EVENTS_DETAILS_SUCCESS:
			return { loading: false, events: action.payload };
		case EVENTS_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const eventsCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case EVENTS_CREATE_REQUEST:
			return { loading: true };
		case EVENTS_CREATE_SUCCESS:
			return { loading: false, success: true, event: action.payload };
		case EVENTS_CREATE_FAIL:
			return { loading: false, error: action.payload };
		case EVENTS_CREATE_RESET:
			return {};
		default:
			return state;
	}
};

export const eventsCopyReducer = (state = {}, action) => {
	switch (action.type) {
		case EVENTS_COPY_REQUEST:
			return { loading: true };
		case EVENTS_COPY_SUCCESS:
			return { loading: false, success: true, events: action.payload };
		case EVENTS_COPY_FAIL:
			return { loading: false, error: action.payload };
		case EVENTS_COPY_RESET:
			return {};
		default:
			return state;
	}
};

export const eventsUpdateReducer = (state = { event: {} }, action) => {
	switch (action.type) {
		case EVENTS_UPDATE_REQUEST:
			return { ...state, loading: true };
		case EVENTS_UPDATE_SUCCESS:
			return { loading: false, success: true, event: action.payload };
		case EVENTS_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case EVENTS_UPDATE_RESET:
			return { event: {} };
		default:
			return state;
	}
};

export const eventsDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case EVENTS_DELETE_REQUEST:
			return { loading: true };
		case EVENTS_DELETE_SUCCESS:
			return { loading: false, success: true };
		case EVENTS_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};