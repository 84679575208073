import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Image,
	AspectRatio,
	Textarea,
	Card,
	CardBody,
	CardFooter,
	Text,
	Divider,
	ButtonGroup,
	Icon
} from '@chakra-ui/react';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import serverIp from '../config/config';
import FormContainer from '../components/FormContainer';
import { createEvents, listAllProgEvents, copyEvents } from '../actions/eventsActions';
import { EVENTS_CREATE_RESET } from '../constants/EventsConstants';
import UploadFile from '../components/UploadFile';
import ImageCropper from '../components/ImageCropper';
import AlertDialogExample from '../components/AlertDialog';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const EventCopyScreen = () => {

	const { id } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();


	const { t } = useTranslation();

	const allProgEventsList = useSelector((state) => state.allProgEventsList);
	const { loading, events, error } = allProgEventsList;

	const eventsCopy = useSelector((state) => state.eventsCopy);
	const { loading: laodingCopy, error: errorCopy, success, events: copiedEents } = eventsCopy;

	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		dispatch(listAllProgEvents());
	}, [dispatch])

	useEffect(() => {
		if (success) {
			navigate(`/program/${id}/events`)
		}
	}, [id, success]);

	const handleItemClick = (id) => {
		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter(item => item !== id));
		} else {
			setSelectedItems([...selectedItems, id]);
		}
	};

	const handleEventCopy = () => {
		dispatch(copyEvents({ eventIdsToCopy: selectedItems, newProgramId: id }))
	}

	const MenuItem = ({ id, imageSrc, itemName, itemDescription }) => {
		const isSelected = selectedItems.includes(id);

		return (
			<a
				className={`flex flex-col pb-4 items-center justify-between border border-gray-200 rounded-lg shadow md:max-w-xl cursor-pointer ${isSelected ? 'bg-orange-200 hover:bg-orange-200' : 'bg-white hover:bg-gray-100'}`}
				onClick={() => handleItemClick(id)}
			>
				<div className='flex flex-col items-center'>
					<img className="object-cover object-center w-40 rounded-lg h-96 md:h-40 md:rounded-none md:rounded-lg" src={imageSrc} alt="" />
					<div className="flex flex-col max-h-1/2 overflow-hidden justify-between p-4 leading-normal">
						<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{itemName}</h5>
						<p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{itemDescription}</p>
					</div>
				</div>

				<div className="flex flex-col gap-2">
					<BsFillHandThumbsUpFill className={`self-center text-2xl text-blue-600 ${isSelected ? "flex animate-jump-in" : "hidden"}`} />
					<button
						className={`py-2 px-4 rounded-md text-white font-semibold ${isSelected ? 'bg-red-500' : 'bg-green-500'}`}
					>
						{isSelected ? 'Abwählen' : 'Auswählen'}
					</button>
				</div>
			</a>
		);
	};

	return (
		<Flex direction='column'>
			<Flex py='2' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
				<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to={`/program/${id}/events`} color='white' alignItems='center' gap='2'>
					<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
					{t("Back")}
				</Flex>
				<Button color='black' bg='gray.200' onClick={handleEventCopy}>
					{t("Copy")}
				</Button>
			</Flex>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Flex flexDirection={{ sm: 'column', md: 'row' }} p='4' gap='4'>
					{events.map((event, index) => (
						<MenuItem
							key={index}
							id={event._id}
							imageSrc={event.thumbImage}
							itemName={event.name}
							itemDescription={event.description}
						/>
					))}
				</Flex>
			)}
		</Flex>
	)
}

export default EventCopyScreen;