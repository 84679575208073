export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const BG_IMAGE_REQUEST = 'BG_IMAGE_REQUEST';
export const BG_IMAGE_SUCCESS = 'BG_IMAGE_SUCCESS';
export const BG_IMAGE_FAIL = 'BG_IMAGE_FAIL';

export const BG_IMAGE_UPDATE_REQUEST = 'BG_IMAGE_REQUEST';
export const BG_IMAGE_UPDATE_SUCCESS = 'BG_IMAGE_SUCCESS';
export const BG_IMAGE_UPDATE_FAIL = 'BG_IMAGE_FAIL';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DOUBT_LIST_REQUEST = 'USER_DOUBT_LIST_REQUEST';
export const USER_DOUBT_LIST_SUCCESS = 'USER_DOUBT_LIST_SUCCESS';
export const USER_DOUBT_LIST_FAIL = 'USER_DOUBT_LIST_FAIL';
export const USER_DOUBT_LIST_RESET = 'USER_DOUBT_LIST_RESET';

export const USER_LIST_DETAILS_REQUEST = 'USER_LIST_DETAILS_REQUEST';
export const USER_LIST_DETAILS_SUCCESS = 'USER_LIST_DETAILS_SUCCESS';
export const USER_LIST_DETAILS_FAIL = 'USER_LIST_DETAILS_FAIL';
export const USER_LIST_DETAILS_RESET = 'USER_LIST_DETAILS_RESET';

export const FEEDBACK_USER_LIST_REQUEST = 'FEEDBACK_USER_LIST_REQUEST';
export const FEEDBACK_USER_LIST_SUCCESS = 'FEEDBACK_USER_LIST_SUCCESS';
export const FEEDBACK_USER_LIST_FAIL = 'FEEDBACK_USER_LIST_FAIL';
export const FEEDBACK_USER_LIST_RESET = 'FEEDBACK_USER_LIST_RESET';

export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_FAIL = 'FEEDBACK_FAIL';
export const FEEDBACK_RESET = 'FEEDBACK_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';