import axios from 'axios';

import {
    PROGRAMS_LIST_FAIL,
    PROGRAMS_LIST_REQUEST,
    PROGRAMS_LIST_SUCCESS,
	PROGRAMS_DETAILS_FAIL,
	PROGRAMS_DETAILS_REQUEST,
	PROGRAMS_DETAILS_SUCCESS,
	PROGRAMS_CREATE_FAIL,
	PROGRAMS_CREATE_REQUEST,
	PROGRAMS_CREATE_SUCCESS,
	PROGRAMS_DELETE_FAIL,
	PROGRAMS_DELETE_REQUEST,
	PROGRAMS_DELETE_SUCCESS,
	PROGRAMS_UPDATE_FAIL,
	PROGRAMS_UPDATE_REQUEST,
	PROGRAMS_UPDATE_SUCCESS,
	FEEDBACK_PROGRAMS_LIST_FAIL,
	FEEDBACK_PROGRAMS_LIST_REQUEST,
	FEEDBACK_PROGRAMS_LIST_SUCCESS,
} from '../constants/programsConstants';
import serverIp from '../config/config';

export const listPrograms = (category, liveStatus) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROGRAMS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs?category=${category}&liveStatus=${liveStatus}`, config);

		dispatch({ type: PROGRAMS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROGRAMS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listProgramDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROGRAMS_DETAILS_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/programs/${id}`, config);

		dispatch({ type: PROGRAMS_DETAILS_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROGRAMS_DETAILS_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const createPrograms = (program) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROGRAMS_CREATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(`${serverIp}/api/coach/programs`, program, config);

		dispatch({ type: PROGRAMS_CREATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROGRAMS_CREATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updatePrograms = (program) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROGRAMS_UPDATE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(
			`${serverIp}/api/coach/programs/${program._id}`,
			program,
			config
		);

		dispatch({ type: PROGRAMS_UPDATE_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: PROGRAMS_UPDATE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const deletePrograms = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PROGRAMS_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`${serverIp}/api/coach/programs/${id}`, config);

		dispatch({ type: PROGRAMS_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: PROGRAMS_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listFeedbackPrograms = () => async (dispatch, getState) => {
	try {
		dispatch({ type: FEEDBACK_PROGRAMS_LIST_REQUEST });

        const {
			userLogin: { userInfo },
		} = getState();

        const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`${serverIp}/api/coach/feedbackProg`, config);

		dispatch({ type: FEEDBACK_PROGRAMS_LIST_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: FEEDBACK_PROGRAMS_LIST_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};