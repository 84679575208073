import {
	Box,
	Flex,
	Heading,
    Image,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Text
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listOrders } from '../actions/orderActions';
import Loader from '../components/Loader';
import Message from '../components/Message';

const OrderListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const orderList = useSelector((state) => state.orderList);
	const { loading, error, orders } = orderList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const { t } = useTranslation();

	useEffect(() => {
		if (userInfo && userInfo.isCoach) {
			dispatch(listOrders());
		} else {
			navigate('/login');
		}
	}, [dispatch, userInfo, navigate]);

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{sm:'390px', lg:'full'}}>
            <Flex py='4' bg='black' px={{sm:'5px', lg:'40px'}} alignItems='center' justifyContent='space-between'>
				<Heading fontSize='xl' as={RouterLink} to='/home' color='white'>
					{t("Home")}
				</Heading>
				<Heading fontSize='xl' color='white'>
					{t("Orders")}
				</Heading>
			</Flex>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5'>
					<Table variant='striped' colorScheme='gray' size='sm'>
						<Thead>
							<Tr>
                            <Th fontSize={{sm:'11px',md:'sm'}}>{t("IMAGE")}</Th>
								<Th fontSize={{sm:'11px',md:'sm'}}>{t("NAME")}</Th>
								<Th display={{ sm: 'none', md:'table-cell'}}>{t("EMAIL")}</Th>
								<Th display={{sm:'none', md:'table-cell'}}>{t("POSITION")}</Th>
								<Th display={{sm:'none', md:'table-cell'}}>{t("ORGANIZATION")}</Th>
								<Th fontSize={{sm:'11px',md:'sm'}} display={{sm:'none', md:'table-cell'}}>{t("ORDER ID")} {"."}</Th>
								<Th fontSize={{sm:'11px',md:'sm'}} display={{sm:'table-cell', md:'none'}}>{t("ORDER ID")} {"& PRICE"}</Th>
								<Th fontSize={{sm:'11px',md:'sm'}} display={{sm:'none', md:'table-cell'}}>{t("PRICE")}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{orders.orders.map((order) => (
								<Tr color={!order.isPaid && 'red'}>
									<Td>                    
                                        <Image src={order.user.image} h={{sm:'35px',lg:'70px'}} w={{sm:'35px',lg:'70px'}} />
                                    </Td>
									<Td>
										<Flex direction='column' gap='2px'>
										<Text fontSize={{sm:'12px',md:'sm'}} fontWeight='bold'>{order.user.name}</Text>
										<Text fontSize={{sm:'10px',md:'sm'}} color='green.300' fontWeight='bold'>{order.programName}</Text>
										<Text fontSize='10px'>{order.paymentResult?.update_time? new Date(order.paymentResult.update_time).toLocaleString() : ''}</Text>
										</Flex>
									</Td>
									<Td display={{ sm: 'none', md:'table-cell'}}>
										<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
									</Td>
									<Td display={{sm:'none', md:'table-cell'}}>{order.user.position}</Td>
									<Td display={{sm:'none', md:'table-cell'}}>{order.user.orgName}</Td>
                                    <Td >
										<Flex direction='column'>
											<Text fontSize={{sm:'10px',md:'sm'}}>{order._id}</Text>
											<Text fontSize={{sm:'10px',md:'sm'}} display={{sm:'flex', md:'none'}}>{order.price.toFixed(2)} €</Text>
										</Flex>
									</Td>
									<Td fontSize={{sm:'10px',md:'sm'}} display={{sm:'none', md:'table-cell'}}>{order.price.toFixed(2)} €</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			)}
		</Flex>
        </Flex>
	);
};

export default OrderListScreen;