import {
	Button,
	Flex,
	Heading,
	Icon,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoAddCircleOutline,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProgEvents } from '../actions/eventsActions';
import EventsListCard from '../components/EventListCard';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { EVENTS_COPY_RESET } from '../constants/EventsConstants';

const ProgEventsListScreen = () => {
	const dispatch = useDispatch();
	const { id: Progid } = useParams();

	const progEventsList = useSelector((state) => state.progEventsList);
	const { loading: eveLoading, error: eveError, events } = progEventsList;

	const eventsDelete = useSelector((state) => state.eventsDelete);
	const {
		success: successDelete,
	} = eventsDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listProgEvents(Progid));
		dispatch({ type: EVENTS_COPY_RESET });
	}, [dispatch, successDelete, Progid]);

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='2' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
				<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to={`/programs/${Progid}`} color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Program")}
					</Flex>
					<Flex gap='2'>
						<Button bgColor='gray.200' color='black' as={RouterLink} to={`/programs/${Progid}/events/copy`}>
							<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Copy Events")}
						</Button>
						<Button bgColor='gray.200' color='black' as={RouterLink} to={`/programs/${Progid}/events/add`}>
							<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Add Event")}
						</Button>
					</Flex>
				</Flex>
				<Flex>
					{eveLoading ? (
						<Loader />
					) : eveError ? (
						<Message type='error'>{eveError}</Message>
					) : (
						<Flex pt='5px' direction={{ sm: 'column', lg: 'row' }} gap='2' flexWrap='wrap' px={{ sm: '5px', lg: '66px' }}>
							{events.event.map((e) => (
								<EventsListCard key={e._id} events={e} progId={Progid} />
							))}
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ProgEventsListScreen;
