import { Flex, Icon, Button, Heading, Text, Image, Textarea } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IoPencilSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { aboutusDetails as aboutDetails } from '../actions/infoActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const AboutScreen = () => {
	const dispatch = useDispatch();

	const aboutusDetails = useSelector((state) => state.aboutusDetails);
	const { loading, error, aboutus } = aboutusDetails;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(aboutDetails());
	}, [dispatch]);

	return (
		<Flex direction="column" w="full">
			<Flex
				bg="black"
				py="2"
				px={{ base: '5px', md: '40px' }}
				alignItems="center"
				justifyContent="space-between"
				color="white"
				fontWeight="bold"
			>
				<RouterLink to="/home" display="flex" alignItems="center" gap="2">
					<Icon as={FaRegArrowAltCircleLeft} boxSize="6" />
					{t("Home")}
				</RouterLink>
				{loading ? (
					<Loader />
				) : error ? (
					<Message type="error">{error}</Message>
				) : (
					<>
						{aboutus.aboutUs.map((obj) => (
							<Button
								key={obj._id}
								as={RouterLink}
								to={`/aboutus/${obj._id}/edit`}
								bgColor="gray.200"
								color="black"
								leftIcon={<Icon as={IoPencilSharp} boxSize="5" />}
							>
								{t("Edit AboutUs")}
							</Button>
						))}
					</>
				)}
			</Flex>

			<Flex
				pt="5px"
				direction={{ base: 'column', md: 'row' }}
				gap="2"
				px={{ base: '5px', md: '70px' }}
			>
				{aboutus.aboutUs.map((obj) => (
					<Flex
						key={obj._id}
						direction="column"
						alignItems="center"
						width="full"
						bg="white"
						boxShadow="md"
						borderRadius="md"
						p="4"
					>
						<Heading size="xl" mb="3">
							{obj.title}
						</Heading>
						<Image src={obj.image} w="100%" h="auto" objectFit="cover" mb="3" />
						<Textarea
							width="full"
							minH='350px'
							textAlign="justify"
							value={obj.description}
							readOnly
							resize="none"
							bg="transparent"
							border="none"
							_focus={{ border: 'none' }}
						/>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};

export default AboutScreen;