import { Center, Text, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const FeatureBox = ({ icon, fName, goTo }) => {
	
    return (
		<Center as={RouterLink} w='31%' 
            h={{sm:'125px',lg:'52%'}} 
            boxShadow='md'
            _hover={{ zIndex:'1', boxShadow:'md', transform:'scale(1.01)', transitionDuration:'300ms' }} 
            bg='white'  
            rounded='md'
            boxSizing='border-box'
            padding='1rem'
            to={goTo}
            >
            <Center flexDirection='column'>
				<Icon as={icon} h='30px' w='30px' mt='5px'/>
                <Text align='center' fontSize='sm' as='b' mt='10px'>
                    {fName}
                </Text>
            </Center>
        </Center>
	);
};

export default FeatureBox;