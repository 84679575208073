import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Table,
    Image,
	Tbody,
    Text,
	Td,
	Th,
	Thead,
	Tr,
    Checkbox,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoCheckmarkCircleSharp,
	IoCloseCircleSharp,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listUsers } from '../actions/userActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { assignTasks } from '../actions/tasksActions';

const AssignTaskScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

    let setUsers = [];

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	const tasksAssign = useSelector((state) => state.tasksAssign);
	const { success: successAssign } = tasksAssign;

	const { t } = useTranslation();

	useEffect(() => {
		if (userInfo && userInfo.isCoach) {
			dispatch(listUsers());
		} else {
			navigate('/login');
		}
	}, [dispatch, navigate, userInfo, successDelete]);

    const handleClick = (e) => {
		if (e.target.checked === true) {
			if(setUsers.find)
			setUsers.push(e.target.value);
		} else {
			setUsers = setUsers.filter(id => id !== e.target.value);
		}
    }
	const assignHandler = () => {
		if (setUsers && setUsers.length !== 0) {
			dispatch(assignTasks(id, setUsers));
		}
		if (successAssign) {
			navigate('/tasks');
		}
	}

	return (
			<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{sm:'390px', md:'full'}}>
            <Flex py='4' bg='black' px={{sm:'5px', md:'40px'}} alignItems='center' justifyContent='space-between'>
				<Heading fontSize='xl' as={RouterLink} to='/tasks' color='white'>
					{t("Tasks")}
				</Heading>
				<Heading fontSize='xl' color='white'>
					{t("Assign Task")}
				</Heading>
			</Flex>
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{sm:'390px', md:'full'}}>
					<Table variant='striped' colorScheme='gray' size='sm'>
						<Thead>
							<Tr>
								<Th>{t("IMAGE")}</Th>
								<Th display={{ sm: 'none', md:'table-cell'}}>{t("NAME")}</Th>
								<Th display={{ sm: 'table-cell', md:'none'}}>{t("DETAILS")}</Th>
								<Th display={{ sm: 'none', md:'table-cell'}}>{t("EMAIL")}</Th>
								<Th display={{ sm: 'none', md:'table-cell'}}>{t("POSITION")}</Th>
								<Th display={{ sm: 'none', md:'table-cell'}}>{t("ORGANIZATION")}</Th>
								<Th>{t("IS COACH")}</Th>
								<Th>
                                <Flex justifyContent='flex-end' mr='5' gap='24px'>
										<Text>{t("ASSIGN")}</Text>
									</Flex>
                                </Th>
							</Tr>
						</Thead>
						<Tbody>
							{users.users.map((user) => (
								<Tr key={user._id}>
									<Td>                    
                                        <Image src={user.image} h={{sm:'35px',lg:'70px'}} w={{sm:'35px',lg:'70px'}} />
                                    </Td>
									<Td>
										<Flex direction='column' gap='2px'>
											<Text fontSize='sm' fontWeight='bold'>{user.name}</Text>
											<Text fontSize='10px' display={{ sm: 'flex', md:'none'}}>{user.position}</Text>
											<Text fontSize='10px' display={{ sm: 'flex', md:'none'}}>{user.orgName}</Text>
										</Flex>
									</Td>
									<Td display={{ sm: 'none', md:'table-cell'}}>
										<a href={`mailto:${user.email}`}>{user.email}</a>
									</Td>
									<Td display={{ sm: 'none', md:'table-cell'}}>{user.position}</Td>
									<Td display={{ sm: 'none', md:'table-cell'}}>{user.orgName}</Td>
									<Td>
										{user.isCoach ? (
                                            <Flex>
											<Icon
												as={IoCheckmarkCircleSharp}
												color='green.600'
												w='8'
												h='8'
											/>
                                            </Flex>
										) : (
                                            <Flex pl='3.5'>
											<Icon
												as={IoCloseCircleSharp}
												color='red.600'
												w='8'
												h='8'
											/>
                                            </Flex>
										)}
									</Td>
									<Td>
										<Flex justifyContent='flex-end' alignItems='center' pr='35px'>
											{/* <Button
												mr='4'
												colorScheme='teal'>
                                                ASSIGN
												<Icon as={IoPencilSharp} color='white' size='sm' />
											</Button> */}
                                            <Checkbox type='checkbox' borderColor='black' colorScheme='green' name={user.name} onChange={handleClick} value={user._id}/>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					<Flex justifyContent='center' p='3'>
					<Button onClick={assignHandler} color='white' colorScheme='teal'>
						{t("Assign")}
					</Button>
					</Flex>
				</Box>
			)}
		</Flex>
        </Flex>
	);
};

export default AssignTaskScreen;