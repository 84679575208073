import { Flex, Icon, Button, Heading, Image, Text, Link, AspectRatio, Card, CardBody, Box } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { IoPencilSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listEventDetails } from '../actions/eventsActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const EventScreen = () => {

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const progId = queryParams.get('progId');

	const { id } = useParams();
	const dispatch = useDispatch();

	const eventsDetails = useSelector((state) => state.eventsDetails);
	const { loading, error, events } = eventsDetails;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listEventDetails(id));
	}, [dispatch, id]);

	return (
		<>
			<Flex direction='column' w='full'>
				<Flex h='full' direction='column' w={{ sm: 'full', md: 'full' }}>
					<Flex bg='black' py='2' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
						<Flex fontSize='xl' fontWeight='bold' color='white' as={RouterLink} to={(progId && progId !== 'null') ? `/program/${progId}/events` : `/events`} alignItems='center' gap='2' >
							<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
							{t("Event")}
						</Flex>
						<Button as={RouterLink} to={`/events/${id}/edit`} color='black' bg='gray.200'>
							<Icon as={IoPencilSharp} boxSize='5' /> {t("Edit Event")}
						</Button>
					</Flex>

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<Flex pt='5px' direction='column' px={{ sm: '5px', md: '70px' }}>
							<Flex direction='column' pt='3'>
								<Flex direction={{ sm: 'column', md: 'row' }} gap='3' >
									<Flex direction='column' gap='2' py='2'>
										<AspectRatio ratio={16 / 9}>
											<Image rounded='md' src={events.thumbImage} alt={events.name} w={{ sm: '100%', md: '400px' }} h={{ sm: '270px', md: '300px' }} display={events.thumbImage ? 'flex' : 'none'} />
										</AspectRatio>
										<Box w={{ sm: '100%', md: '450px' }} display={events.thumbVid ? 'flex' : 'none'}>
											{/* <video src={programs.thumbVid} controls /> */}
											<ReactPlayer url={events.thumbVid} controls width='450px' height='290px' />
										</Box>
									</Flex>

									<Flex direction='column' width='100%'>
										<Flex direction='row' justifyContent='space-between'>
											<Heading
												as='h2'
												fontSize='2xl'
												fontWeight='bold'
												color='teal.600'>
												{events.name}
											</Heading>

											<Heading
												as='h6'
												fontSize='sm'
												fontWeight='bold'
												color='blue.600'>
												{`${new Date(events.setTime).toLocaleString('de-DE', { hour12: false })}`}
											</Heading>
										</Flex>
										<Flex direction='column' pt='2'>
											<Text fontWeight='bold'>{events.result}</Text>
											<Text py={{ sm: '', md: '2' }} textAlign='justify'>{events.description}</Text>
										</Flex>
										<Flex alignSelf='center' py='5' direction={{ sm: 'column', md: 'row' }} gap='2' justifyContent='center'>
											<Link href={events.eventLink} target='_blank'><Button>Event Link</Button></Link>
										</Flex>
									</Flex>
								</Flex>
								{events.media && <Flex direction='column' gap='2' pb='5'>
									<Heading fontSize='xl'>Additional Media</Heading>
									<Card maxW='md' bgColor='white'>
										<CardBody>
											<Flex display={events.media.type === 'image' ? 'flex' : 'none'}>
												<Image
													src={events.media.url}
													alt={events.media.name}
													borderRadius='lg'
												/>
											</Flex>

											<Flex display={events.media.type === 'video' ? 'flex' : 'none'}>
												<video src={events.media.url} controls />
											</Flex>
											<Text color='black' fontSize='xl' fontWeight='bold'>
												{events.media.name}
											</Text>

											<Text color='black'>
												{events.media.description}
											</Text>
										</CardBody>
									</Card>
								</Flex>}
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default EventScreen;