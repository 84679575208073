import { 
    SUPPORT_DETAILS_FAIL,
    SUPPORT_DETAILS_REQUEST,
    SUPPORT_DETAILS_SUCCESS,
    ABOUTUS_DETAILS_FAIL,
    ABOUTUS_DETAILS_REQUEST,
    ABOUTUS_DETAILS_SUCCESS,
	SUPPORT_UPDATE_FAIL,
	SUPPORT_UPDATE_REQUEST,
	SUPPORT_UPDATE_RESET,
	SUPPORT_UPDATE_SUCCESS,
	ABOUTUS_UPDATE_FAIL,
	ABOUTUS_UPDATE_REQUEST,
	ABOUTUS_UPDATE_RESET,
	ABOUTUS_UPDATE_SUCCESS,
 } from "../constants/infoConstants";

 export const supportDetailsReducer = (state = { support: { support:[] } }, action) => {
	switch (action.type) {
		case SUPPORT_DETAILS_REQUEST:
			return { ...state, loading: true };
		case SUPPORT_DETAILS_SUCCESS:
			return { loading: false, support: action.payload };
		case SUPPORT_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const aboutusDetailsReducer = (state = { aboutus: { aboutUs:[] } }, action) => {
	switch (action.type) {
		case ABOUTUS_DETAILS_REQUEST:
			return { ...state, loading: true };
		case ABOUTUS_DETAILS_SUCCESS:
			return { loading: false, aboutus: action.payload };
		case ABOUTUS_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const supportUpdateReducer = (state = { editedSupport: {} }, action) => {
	switch (action.type) {
		case SUPPORT_UPDATE_REQUEST:
			return { ...state, loading: true };
		case SUPPORT_UPDATE_SUCCESS:
			return { loading: false, success: true, editedSupport: action.payload };
		case SUPPORT_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case SUPPORT_UPDATE_RESET:
			return { editedSupport: {} };
		default:
			return state;
	}
};

export const aboutUsUpdateReducer = (state = { editedAboutUs: {} }, action) => {
	switch (action.type) {
		case ABOUTUS_UPDATE_REQUEST:
			return { ...state, loading: true };
		case ABOUTUS_UPDATE_SUCCESS:
			return { loading: false, success: true, editedAboutUs: action.payload };
		case ABOUTUS_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case ABOUTUS_UPDATE_RESET:
			return { editedAboutUs: {} };
		default:
			return state;
	}
};