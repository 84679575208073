import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Text,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Progress,
	Tr,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
	IoTrashBinSharp,
	IoCheckmarkCircleSharp,
	IoCloseCircleSharp,
	IoAddCircleOutline
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listUserTasks, deleteTasks } from '../actions/tasksActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const UserTasksListScreen = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const userTasksList = useSelector((state) => state.userTasksList);
	const { loading, error, tasks } = userTasksList;

	const tasksDelete = useSelector((state) => state.tasksDelete);
	const {
		success: successDelete,
	} = tasksDelete;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listUserTasks(id));
	}, [dispatch, successDelete, id]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteTasks(id));
		}
	};

	return (
		<Flex direction='column' w='full'>
			<Flex h='full' direction='column' w={{ sm: '390px', md: 'full' }}>
				<Flex py='2' bg='black' px={{ sm: '5px', md: '40px' }} alignItems='center' justifyContent='space-between'>
					<Flex fontSize='xl' fontWeight='bold' as={RouterLink} to='/usersList' color='white' alignItems='center' gap='2'>
						<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
						{t("Home")}
					</Flex>
					<Button bgColor='gray.200' color='black' as={RouterLink} to={'/tasks/add'}>
						<Icon as={IoAddCircleOutline} boxSize='5' /> {t("Add Task")}
					</Button>
				</Flex>
				{loading ? (
					<Loader />
				) : error ? (
					<Message type='error'>{error}</Message>
				) : (
					<Box bgColor='white' rounded='lg' shadow='lg' px='5' py='5' w={{ sm: '390px', md: 'full' }}>
						<Flex>
							<Heading size='md' pl='1'>{t("SCORE")} {"="}</Heading>
							<Heading size='md' pl='1'>{tasks.score}</Heading>
						</Flex>
						<Progress value={tasks.score} rounded='md' my='2' />
						<Table variant='striped' colorScheme='gray' size='sm'>
							<Thead>
								<Tr>
									<Th>{t("NAME")}</Th>
									<Th>{t("TITLE")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("TIME")}</Th>
									<Th display={{ sm: 'none', md: 'table-cell' }}>{t("DESCRIPTION")}</Th>
									<Th>{t("IS COMPLETED")}</Th>
									<Th>
										<Flex justifyContent='flex-end' mr='1'>
											<Text>{t("DELETE")}</Text>
										</Flex>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{tasks.updatedTasks.map((obj) => (
									<Tr key={obj._id}>
										<Td>
											<Flex as={RouterLink} to={`/tasks/${obj._id}`}>
												{obj.name}
											</Flex>
										</Td>
										<Td>{obj.title}</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>{obj.setTime}</Td>
										<Td display={{ sm: 'none', md: 'table-cell' }}>{obj.description}</Td>
										<Td>
											{obj.isCompleted ? (
												<Flex>
													<Icon
														as={IoCheckmarkCircleSharp}
														color='green.600'
														w='8'
														h='8'
													/>
												</Flex>
											) : (
												<Flex>
													<Icon
														as={IoCloseCircleSharp}
														color='red.600'
														w='8'
														h='8'
													/>
												</Flex>
											)}
										</Td>
										<Td>
											<Flex justifyContent='flex-end' alignItems='center' gap='4'>
												<Button size={{ sm: 'sm', lg: 'md' }} colorScheme='red' onClick={() => deleteHandler(obj._id)} >
													<Icon as={IoTrashBinSharp} />
												</Button>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>
				)}
			</Flex>
		</Flex>
	);
};

export default UserTasksListScreen;
