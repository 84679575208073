import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getListUserDetails, updateUser } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { USER_UPDATE_RESET } from '../constants/userConstants';
import AlertDialogExample from '../components/AlertDialog';

const UserEditScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: userId } = useParams();

	const [name, setName] = useState('');
	const [orgName, setOrgName] = useState('');
	const [position, setPosition] = useState('');
	const [email, setEmail] = useState('');
	const [dob, setDob] = useState('');

	const userListDetails = useSelector((state) => state.userListDetails);
	const { loading, error, user } = userListDetails;

	const userUpdate = useSelector((state) => state.userUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = userUpdate;

	const { t } = useTranslation();

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: USER_UPDATE_RESET });
			dispatch(getListUserDetails(userId));
			navigate('/usersList');
		} else {
			if (!user.user.name || user.user._id !== userId) {
				dispatch(getListUserDetails(userId));
			} else {
				setName(user.user.name);
				setEmail(user.user.email);
				setOrgName(user.user.orgName);
				setPosition(user.user.position);
				setDob(user.user.dob);
			}
		}
	}, [user, dispatch, userId, successUpdate, navigate]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(updateUser({ _id: userId, name, orgName, position, email }));
	};

	return (
		<Flex direction='column' mt='5px'>
			<Flex w='full' alignItems='center' justifyContent='center' px={{ sm: '4px', md: '' }}>
				<FormContainer>
					<Heading as='h1' mb='8' fontSize='3xl' color='white'>
						{t("Edit User")}
					</Heading>

					{loadingUpdate && <Loader />}
					{errorUpdate && <Message type='error'>{errorUpdate}</Message>}

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<form onSubmit={submitHandler}>
							<Flex direction={{ sm: 'column', md: 'row' }} flexWrap={{ sm: 'nowrap', md: 'wrap' }} rowGap={{ sm: '10px', md: '15px' }} columnGap={{ sm: '0', md: '10px' }}>
								<FormControl id='name' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Name")}</FormLabel>
									<Input
										type='text'
										borderColor='white'
										placeholder={t("Enter name")}
										color='white'
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</FormControl>

								<FormControl id='orgname' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Organization Name")}</FormLabel>
									<Input
										type='text'
										color='white'
										borderColor='white'
										placeholder={t("Enter Organization Name")}
										value={orgName}
										onChange={(e) => setOrgName(e.target.value)}
									/>
								</FormControl>

								<FormControl id='position' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Position")}</FormLabel>
									<Input
										type='text'
										color='white'
										borderColor='white'
										placeholder={t("Enter Position")}
										value={position}
										onChange={(e) => setPosition(e.target.value)}
									/>
								</FormControl>

								<FormControl id='email' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Email Address")}</FormLabel>
									<Input
										type='text'
										color='white'
										borderColor='white'
										placeholder={t("Enter email address")}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormControl>

								<FormControl id='dob' width={{ sm: 'full', md: '99%' }}>
									<FormLabel color='white'>{t("Date Of Birth")}</FormLabel>
									<Input
										css={`::-webkit-calendar-picker-indicator {
                            			filter: invert(1);
                        				}`
										}
										type='date'
										borderColor='white'
										disabled
										color='white'
										placeholder={t("Enter email address")}
										value={`${new Date(dob).getFullYear()}-${String(new Date(dob).getMonth() + 1).padStart(2, '0')}-${new Date(dob).getDate()}`}
										onChange={(e) => setDob(e.target.value)}
									/>
								</FormControl>

								<Flex gap='5' mt='6'>
									<Button
										colorScheme='teal'
										color='white'
										type='submit'
										isLoading={loading}>
										{t("Update")}
									</Button>
									<AlertDialogExample />
								</Flex>
							</Flex>
						</form>
					)}
				</FormContainer>
			</Flex>
		</Flex>
	);
};

export default UserEditScreen;