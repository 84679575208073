import { Image, Heading, Icon, Text, Button, Flex, Box } from "@chakra-ui/react";
import { TbTrash } from 'react-icons/tb'
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { deletePrograms } from "../actions/programsActions";

const ProgramsListCard = (program) => {

    const dispatch = useDispatch();

    const deleteHandler = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deletePrograms(id));
        }
    };

    return (
        <Flex _hover={{ textDecor: 'none' }} >
            <Flex p='10px' bgColor='white' rounded='lg' shadow='lg' w={{ sm: 'full', lg: '400px' }} h={{ sm: '110px', lg: '8rem' }} direction='row'>
                <Box as={RouterLink} to={`/programs/${program.program._id}`}>
                    <Image border='solid' rounded='md' src={program.program.thumbImage} alt={program.program.name} h='full' w={{ sm: '94px', lg: '110px' }} objectFit='cover' />
                </Box>
                <Flex w='270px' px={{ sm: '5px', lg: '3px' }} direction='column' justifyContent='space-between'>
                    <Flex justifyContent='space-between' alignItems='center' overflow='clip'>
                        <Heading w='270px' h='16' as={RouterLink} to={`/programs/${program.program._id}`} fontSize={{ sm: 'md', lg: 'lg' }} overflow='clip' >
                            {program.program.name}...
                        </Heading>
                        <Button bg='none' w={{ sm: '5', lg: '3' }} h={{ sm: '5', lg: '8' }} type='button' onClick={() => deleteHandler(program.program._id)}>
                            <Icon as={TbTrash} boxSize={{ sm: '5', lg: '6' }} fontSize='xl' fontWeight='bold' />
                        </Button>
                    </Flex>
                    <Text fontSize={{ sm: 'md', lg: 'lg' }} fontWeight='semibold'>{program.program.price} €</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default ProgramsListCard;