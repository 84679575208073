export const TASKS_LIST_REQUEST = 'TASKS_LIST_REQUEST';
export const TASKS_LIST_SUCCESS = 'TASKS_LIST_SUCCESS';
export const TASKS_LIST_FAIL = 'TASKS_LIST_FAIL';

export const PROG_TASKS_LIST_REQUEST = 'PROG_TASKS_LIST_REQUEST';
export const PROG_TASKS_LIST_SUCCESS = 'PROG_TASKS_LIST_SUCCESS';
export const PROG_TASKS_LIST_FAIL = 'PROG_TASKS_LIST_FAIL';

export const ALL_PROG_TASKS_LIST_REQUEST = 'ALL_PROG_TASKS_LIST_REQUEST';
export const ALL_PROG_TASKS_LIST_SUCCESS = 'ALL_PROG_TASKS_LIST_SUCCESS';
export const ALL_PROG_TASKS_LIST_FAIL = 'ALL_PROG_TASKS_LIST_FAIL';

export const TASKS_DETAILS_REQUEST = 'TASKS_DETAILS_REQUEST';
export const TASKS_DETAILS_SUCCESS = 'TASKS_DETAILS_SUCCESS';
export const TASKS_DETAILS_FAIL = 'TASKS_DETAILS_FAIL';

export const TASKS_CREATE_REQUEST = 'TASKS_CREATE_REQUEST';
export const TASKS_CREATE_SUCCESS = 'TASKS_CREATE_SUCCESS';
export const TASKS_CREATE_FAIL = 'TASKS_CREATE_FAIL';
export const TASKS_CREATE_RESET = 'TASKS_CREATE_RESET';

export const TASKS_COPY_REQUEST = 'TASKS_COPY_REQUEST';
export const TASKS_COPY_SUCCESS = 'TASKS_COPY_SUCCESS';
export const TASKS_COPY_FAIL = 'TASKS_COPY_FAIL';
export const TASKS_COPY_RESET = 'TASKS_COPY_RESET';

export const TASKS_DELETE_REQUEST = 'TASKS_DELETE_REQUEST';
export const TASKS_DELETE_SUCCESS = 'TASKS_DELETE_SUCCESS';
export const TASKS_DELETE_FAIL = 'TASKS_DELETE_FAIL';

export const TASKS_UPDATE_REQUEST = 'TASKS_UPDATE_REQUEST';
export const TASKS_UPDATE_SUCCESS = 'TASKS_UPDATE_SUCCESS';
export const TASKS_UPDATE_FAIL = 'TASKS_UPDATE_FAIL';
export const TASKS_UPDATE_RESET = 'TASKS_UPDATE_RESET';

export const TASKS_ASSIGN_REQUEST = 'TASKS_ASSIGN_REQUEST';
export const TASKS_ASSIGN_SUCCESS = 'TASKS_ASSIGN_SUCCESS';
export const TASKS_ASSIGN_FAIL = 'TASKS_ASSIGN_FAIL';

export const USER_TASKS_LIST_REQUEST = 'USER_TASKS_LIST_REQUEST';
export const USER_TASKS_LIST_SUCCESS = 'USER_TASKS_LIST_SUCCESS';
export const USER_TASKS_LIST_FAIL = 'USER_TASKS_LIST_FAIL';