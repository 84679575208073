import {
    TASKS_LIST_FAIL,
    TASKS_LIST_REQUEST,
    TASKS_LIST_SUCCESS,
	TASKS_DETAILS_FAIL,
	TASKS_DETAILS_REQUEST,
	TASKS_DETAILS_SUCCESS,
	TASKS_CREATE_FAIL,
	TASKS_CREATE_REQUEST,
	TASKS_CREATE_RESET,
	TASKS_CREATE_SUCCESS,
	TASKS_DELETE_FAIL,
	TASKS_DELETE_REQUEST,
	TASKS_DELETE_SUCCESS,
	TASKS_UPDATE_FAIL,
	TASKS_UPDATE_REQUEST,
	TASKS_UPDATE_RESET,
	TASKS_UPDATE_SUCCESS,
	TASKS_ASSIGN_FAIL,
	TASKS_ASSIGN_REQUEST,
	TASKS_ASSIGN_SUCCESS,
	USER_TASKS_LIST_FAIL,
	USER_TASKS_LIST_REQUEST,
	USER_TASKS_LIST_SUCCESS,
	PROG_TASKS_LIST_FAIL,
	PROG_TASKS_LIST_REQUEST,
	PROG_TASKS_LIST_SUCCESS,
	TASKS_COPY_FAIL,
	TASKS_COPY_REQUEST,
	TASKS_COPY_RESET,
	TASKS_COPY_SUCCESS,
	ALL_PROG_TASKS_LIST_REQUEST,
	ALL_PROG_TASKS_LIST_SUCCESS,
	ALL_PROG_TASKS_LIST_FAIL
} from '../constants/tasksConstants';

export const tasksListReducer = (state = { tasks: { task:[] } }, action) => {
	switch (action.type) {
		case TASKS_LIST_REQUEST:
			return { loading: true, tasks: { task:[] } };
		case TASKS_LIST_SUCCESS:
			return { loading: false, tasks: action.payload };
		case TASKS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const progTasksListReducer = (state = { tasks: { updatedTasks:[],score:'' } }, action) => {
	switch (action.type) {
		case PROG_TASKS_LIST_REQUEST:
			return { loading: true, tasks: { task:[] } };
		case PROG_TASKS_LIST_SUCCESS:
			return { loading: false, tasks: action.payload };
		case PROG_TASKS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const allProgTasksListReducer = (state = { tasks:[] }, action) => {
	switch (action.type) {
		case ALL_PROG_TASKS_LIST_REQUEST:
			return { loading: true, tasks:[] };
		case ALL_PROG_TASKS_LIST_SUCCESS:
			return { loading: false, tasks: action.payload };
		case ALL_PROG_TASKS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const tasksDetailsReducer = (state = { tasks: { users:[], doubts:[] } }, action) => {
	switch (action.type) {
		case TASKS_DETAILS_REQUEST:
			return { ...state, loading: true };
		case TASKS_DETAILS_SUCCESS:
			return { loading: false, tasks: action.payload };
		case TASKS_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const tasksCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case TASKS_CREATE_REQUEST:
			return { loading: true };
		case TASKS_CREATE_SUCCESS:
			return { loading: false, success: true, tasks: action.payload };
		case TASKS_CREATE_FAIL:
			return { loading: false, error: action.payload };
		case TASKS_CREATE_RESET:
			return {};
		default:
			return state;
	}
};

export const tasksCopyReducer = (state = {}, action) => {
	switch (action.type) {
		case TASKS_COPY_REQUEST:
			return { loading: true };
		case TASKS_COPY_SUCCESS:
			return { loading: false, success: true, tasks: action.payload };
		case TASKS_COPY_FAIL:
			return { loading: false, error: action.payload };
		case TASKS_COPY_RESET:
			return {};
		default:
			return state;
	}
};

export const tasksUpdateReducer = (state = { task: {} }, action) => {
	switch (action.type) {
		case TASKS_UPDATE_REQUEST:
			return { ...state, loading: true };
		case TASKS_UPDATE_SUCCESS:
			return { loading: false, success: true, task: action.payload };
		case TASKS_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case TASKS_UPDATE_RESET:
			return { task: {} };
		default:
			return state;
	}
};

export const tasksDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case TASKS_DELETE_REQUEST:
			return { loading: true };
		case TASKS_DELETE_SUCCESS:
			return { loading: false, success: true };
		case TASKS_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const tasksAssignReducer = (state = {}, action) => {
	switch (action.type) {
		case TASKS_ASSIGN_REQUEST:
			return { loading: true };
		case TASKS_ASSIGN_SUCCESS:
			return { loading: false, success: true };
		case TASKS_ASSIGN_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const userTasksListReducer = (state = { tasks: { updatedTasks:[], score:0 } }, action) => {
	switch (action.type) {
		case USER_TASKS_LIST_REQUEST:
			return { loading: true, tasks: { task:[] } };
		case USER_TASKS_LIST_SUCCESS:
			return { loading: false, tasks: action.payload };
		case USER_TASKS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};