import '@fontsource/raleway/400.css'
import '@fontsource/open-sans/700.css'
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import "./i18n";
import './index.css';

const breakpoints = {
	sm: '320px',
	md: '768px',
	lg: '960px',
	xl: '1200px',
	'2xl': '1536px',
  }

const theme = extendTheme({
	fonts: {
	  heading: `'Open Sans', sans-serif`,
	  body: `'Raleway', sans-serif`,
	},
	breakpoints
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</Provider>
	</React.StrictMode>
);