import { Flex, Icon, Button, Heading, Box, Image, Text, Link, AspectRatio, Card, CardBody } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoPencilSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { listTaskDetails } from '../actions/tasksActions';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const TaskScreen = () => {

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const progId = queryParams.get('progId');

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const tasksDetails = useSelector((state) => state.tasksDetails);
	const { loading, error, tasks } = tasksDetails;

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(listTaskDetails(id));
	}, [dispatch, id]);

	const handleClick = () => {
		navigate(-1); // navigate to the previous page
	}

	return (
		<>
			<Flex direction='column' w='full'>
				<Flex h='full' direction='column' w={{ sm: 'full', lg: 'full' }}>
					<Flex bg='black' py='2' px={{ sm: '5px', lg: '40px' }} alignItems='center' justifyContent='space-between'>
						<Flex fontSize='xl' fontWeight='bold' color='white' as={RouterLink} to={(progId && progId !== 'null') ? `/program/${progId}/tasks` : `/tasks`} alignItems='center' gap='2' >
							<Icon as={FaRegArrowAltCircleLeft} boxSize='6' color='white' />
							{t("Tasks")}
						</Flex>
						<Button as={RouterLink} to={`/tasks/${id}/edit`} bgColor='white' color='black'>
							<Icon as={IoPencilSharp} boxSize='5' /> {t("Edit Task")}
						</Button>
					</Flex>

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<Flex pt='5px' direction='column' px={{ sm: '5px', lg: '70px' }}>
							<Flex direction='column' pt='3'>
								<Flex direction={{ sm: 'column', md: 'row' }} gap='3'>
									<Flex direction='column' gap='2' py='2'>
										<AspectRatio ratio={16 / 9}>
											<Image rounded='md' src={tasks.thumbImage} alt={tasks.name} w={{ sm: '100%', md: '400px' }} h={{ sm: '270px', md: '300px' }} display={tasks.thumbImage ? 'flex' : 'none'} />
										</AspectRatio>
										<Box w={{ sm: '100%', md: '450px' }} display={tasks.thumbVid ? 'flex' : 'none'}>
											{/* <video src={programs.thumbVid} controls /> */}
											<ReactPlayer url={tasks.thumbVid} controls width='450px' height='290px' />
										</Box>
									</Flex>

									<Flex direction='column' width='100%'>
										<Flex direction='row' justifyContent='space-between'>
											<Heading
												as='h2'
												fontSize='2xl'
												fontWeight='bold'
												color='teal.600'>
												{tasks.title}
											</Heading>
											<Heading
												as='h6'
												fontSize='sm'
												fontWeight='bold'
												color='blue.600'>
												{`${new Date(tasks.createdAt).toLocaleString('de-DE', { hour12: false })}`}
											</Heading>
										</Flex>
										<Heading as='h4' fontSize='xl'>
											{tasks.result}
										</Heading>
										<Flex>
											<Text py={{ sm: '', md: '2' }} textAlign='justify'>{tasks.description}</Text>
										</Flex>
									</Flex>
								</Flex>

								{tasks.images && tasks.images.map((mediaObject, index) => (
									<Flex direction='column' key={index} mt={4} gap='2'>
										<Heading fontSize='xl'>Images</Heading>
										<Card maxW='md' bgColor='white'>
											<CardBody>
												{mediaObject.type === 'image' && (
													<Image src={mediaObject.url} alt={mediaObject.name} borderRadius='lg' />
												)}
												{mediaObject.type === 'video' && (
													<video src={mediaObject.url} controls />
												)}
												<Text color='black' fontSize='xl' fontWeight='bold'>{mediaObject.name}</Text>
												<Text color='black'>{mediaObject.description}</Text>
											</CardBody>
										</Card>
									</Flex>
								))}


								{tasks.videos && tasks.videos.map((mediaObject, index) => (
									<Flex direction='column' key={index} mt={4} gap='2'>
										<Heading fontSize='xl'>Videos</Heading>
										<Card maxW='md' bgColor='white'>
											<CardBody>
												{mediaObject.type === 'image' && (
													<Image src={mediaObject.url} alt={mediaObject.name} borderRadius='lg' />
												)}
												{mediaObject.type === 'video' && (
													<video src={mediaObject.url} controls />
												)}
												<Text color='black' fontSize='xl' fontWeight='bold'>{mediaObject.name}</Text>
												<Text color='black'>{mediaObject.description}</Text>
											</CardBody>
										</Card>
									</Flex>
								))}

								{tasks.document && tasks.document.map((docObj, index) => (
									<Flex key={index} py='5' direction='column' w='150px'>
										<Text fontWeight='bold'>Attached Document</Text>
										<Link href={docObj} target='_blank' >
											<Image src={docObj.split('.').pop() === 'pdf' ? '../../pdfimg.png' : '../../excelimg.png'} alt='PDF/EXCEL' h='140px' w='140px' />
										</Link>
									</Flex>
								))}
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default TaskScreen;