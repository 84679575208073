import { Flex, Icon, Image, Button, Heading, Center, Text, AspectRatio } from '@chakra-ui/react';
import { IoPencilSharp } from 'react-icons/io5';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { TbCalendarStats } from 'react-icons/tb';
import { FaUsers, FaTasks } from 'react-icons/fa';
import { TfiLightBulb } from 'react-icons/tfi';
import { MdOutlineContactSupport, MdOutlineFeedback } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import Header from '../components/Header';
import FormContainer from '../components/FormContainer';
import FeatureBox from '../components/FeatureBox';
import { bgImage as bgImageGet, bgImageUpdate } from '../actions/userActions';
import ImageCropper from '../components/ImageCropper';

const HomeScreen = () => {

	const dispatch = useDispatch();

	const [isShown, setIsShown] = useState(false);

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, error } = userLogin;

	const img = useSelector((state) => state.bgImageUpdate);
	const { bgImage } = img

	const [image, setImage] = useState(null);

	const { t } = useTranslation();

	const handleClick = () => {
		setIsShown(current => !current);
	};

	useEffect(() => {
		dispatch(bgImageGet());
		dispatch(bgImageUpdate());
	}, [image, dispatch, isShown]);

	return (
		<Flex direction='column' w='100%'>
			<Header />
			{loading ? (
				<Loader />
			) : error ? (
				<Message type='error'>{error}</Message>
			) : (
				<Flex direction={{ sm: 'column', md: 'row' }} h={{ sm: '', md: '90vh' }} py={{ sm: '', md: '10px' }} justifyContent='center'>
					{isShown && <Flex id='myForm' w={{ sm: '100%', md: '500px' }} h='90vh' zIndex='999999' justifyContent='center' alignItems='center' pos='absolute'>
						<FormContainer>
							<Heading as='h1' mb='8' fontSize='3xl' color='white'>
								{t("Edit Image")}
							</Heading>

							{loading ? (
								<Loader />
							) : error ? (
								<Message type='error'>{error}</Message>
							) : (
								<form>
									<Flex w='450px' justifyContent='center' alignItems='center' direction='column'>
									<ImageCropper route='uploads' setIsShown={setIsShown} setThumbImage={setImage} thumbImage={image}/>
									<Button
										onClick={handleClick}
										ml='15px'
										w='95px'
										colorScheme='teal' color='white'
										mt='4'>
										{("Cancel")}
									</Button>
									</Flex>
								</form>
							)}
						</FormContainer>
					</Flex>}
					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						<Flex gap='2' direction='column' pl={{ sm: '5px', md: '10px' }} pr={{ sm: '5px', md: '0px' }} mt={{ sm: '65px', md: '77px' }} w={{ sm: '100%', md: '62%' }} h={{ sm: '', md: '100%' }}>
							<AspectRatio ratio={16 / 9}>
								<Image src={image ? `${image}` : `${bgImage.src}`} objectFit='cover' />
							</AspectRatio>
							<Button
								className='open-button'
								leftIcon={<Icon as={IoPencilSharp} mr='-4px' h='10' w='5' />}
								h={{ sm: '', md: '6%' }}
								w={{ sm: '', md: '3%' }}
								margin='5px 0px 0px 5px'
								colorScheme='yellow'
								color='black'
								onClick={handleClick}
								position='absolute'
							// _hover={{bgColor:'gray.400'}}
							>
							</Button>
						</Flex>
					)}
					<Flex direction='column' mt={{ sm: '7px', md: '77px' }} w={{ sm: '100%', md: '38%' }}>
						<Flex as={Center} gap='12px' h='35%'>
							<Center as={RouterLink} to={'/programs'} w={{ sm: '47%', md: '47%' }} h={{ sm: '150px', md: '100%' }} boxShadow='md'
								bg='white' flexDirection='column' rounded='md' _hover={{ zIndex: '1', boxShadow: 'md', transform: 'scale(1.01)', transitionDuration: '300ms' }}
							>
								<Center>
									<Image src='images/program_icon.png' boxSize='100px' />
								</Center>
								<Center>
									<Text size='md' as='b'>
										{t("Programs")}
									</Text>
								</Center>
							</Center>
							<Center as={RouterLink} to={'/doubtList'} w={{ sm: '47%', md: '47%' }} h={{ sm: '150px', lg: '100%' }} bg='white' boxShadow='md'
								rounded='md' flexDirection='column' _hover={{ zIndex: '1', boxShadow: 'md', transform: 'scale(1.01)', transitionDuration: '300ms' }}>
								<Center>
									<Image src='images/doubts_icon.png' boxSize='100px' />
								</Center>
								<Center>
									<Text size='md' as='b'>
										{t("Question")}
									</Text>
								</Center>
							</Center>
						</Flex>
						<Flex as={Center} columnGap={{ sm: '10px', lg: '12px' }} h='64%' rowGap={{ sm: '13px', lg: '12px' }} mt={{ sm: '', md: '-3px' }} py={{ sm: '10px', md: '15px' }} flexWrap={{ sm: 'wrap', lg: 'wrap' }} >
							<FeatureBox icon={TbCalendarStats} fName={t('Events')} goTo='/events' />
							<FeatureBox icon={FaUsers} fName={t('User List')} goTo='/usersList' />
							<FeatureBox icon={FaTasks} fName={t('Add Task')} goTo='/tasks' />
							<FeatureBox icon={TfiLightBulb} fName={t('About Us')} goTo='/aboutus' />
							<FeatureBox icon={MdOutlineContactSupport} fName={t('Support')} goTo='/support' />
							<FeatureBox icon={MdOutlineFeedback} fName={t('Feedback')} goTo='/feedProg' />
						</Flex>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default HomeScreen;