import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
	Textarea,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { supportDetails as supDetails, updateSupport } from '../actions/infoActions';
import { SUPPORT_UPDATE_RESET } from '../constants/infoConstants';
import AlertDialogExample from '../components/AlertDialog';
import ImageCropper from '../components/ImageCropper';

const SupportEditScreen = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { supportId } = useParams();

	const [image, setImage] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [callus, setCallus] = useState('');
	const [mailus, setMailus] = useState('');
	const [appointmentLink, setAppointmentLink] = useState('');
	const [isShown, setIsShown] = useState(true);

	const [uploadingImg, setUploadingImg] = useState(false);

	const supportDetails = useSelector((state) => state.supportDetails);
	const { loading, error, support } = supportDetails;

	const supportUpdate = useSelector((state) => state.supportUpdate);
	const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = supportUpdate;

	const { t } = useTranslation();

	useEffect(() => {
		if (support.support.length === 0) {
			dispatch(supDetails());
		}
		if (successUpdate) {
			dispatch({ type: SUPPORT_UPDATE_RESET });
			navigate(`/support`);
		}
		if (support.support.length !== 0) {
			setImage(support.support[0].image);
			setTitle(support.support[0].title);
			setDescription(support.support[0].description);
			setCallus(support.support[0].callUs);
			setMailus(support.support[0].mailUs);
			setAppointmentLink(support.support[0].appointmentLink);
		}
	}, [dispatch, navigate, supportId, successUpdate, support.support]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (support.support[0].image === image) {
			dispatch(
				updateSupport({
					title,
					description,
					callUs: callus,
					mailUs: mailus,
					appointmentLink
				})
			);
		} else {
			dispatch(
				updateSupport({
					title,
					image,
					description,
					callUs: callus,
					mailUs: mailus,
					appointmentLink
				})
			);
		}
	};

	return (
		<Flex direction='column' mt='5px'>

			<Flex w='full' alignItems='center' justifyContent='center' px={{ sm: '4px', md: '' }}>
				<FormContainer>
					<Heading as='h1' mb='8' fontSize='3xl' color='white'>
						{t("Edit Support")}
					</Heading>

					{loadingUpdate && <Loader />}
					{errorUpdate && <Message type='error'>{errorUpdate}</Message>}

					{loading ? (
						<Loader />
					) : error ? (
						<Message type='error'>{error}</Message>
					) : (
						//onSubmit={submitHandler}
						<form onSubmit={submitHandler}>
							<Flex direction={{ sm: 'column', md: 'row' }} flexWrap={{ sm: 'nowrap', md: 'wrap' }} rowGap={{ sm: '10px', md: '15px' }} columnGap={{ sm: '0', md: '10px' }}>
								{/* TITLE */}
								<FormControl id='title' >
									<FormLabel color='white' >{t("Title")}</FormLabel>
									<Input
										color='white'
										borderColor='white'
										type='text'
										placeholder={t("Enter title")}
										value={title}
										onChange={(e) => setTitle(e.target.value)}
									/>
								</FormControl>


								{/* IMAGE */}
								<FormControl id='thumbimage' width={{ sm: 'full', md: '49%' }}>
									<FormLabel color='white'>{t("Image")}</FormLabel>
									<Flex justifyContent='center'>
										<Flex>
										<ImageCropper route='uploads/supportImg' setIsShown={setIsShown} thumbImage={image} setThumbImage={setImage} />
										</Flex>
									</Flex>
									{uploadingImg && <Loader />}
								</FormControl>

								{/* DESCRIPTION */}
								<FormControl id='description' >
									<FormLabel color='white'>{t("Description")}</FormLabel>
									<Input
										as={Textarea}
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter description")}
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
								</FormControl>

								{/* CALL US */}
								<FormControl id='callus' >
									<FormLabel color='white'>{t("Call Us")}</FormLabel>
									<InputGroup>
										<InputLeftAddon borderColor='white' children='+49' />
										<Input
											color='white'
											borderColor='white'
											type='number'
											placeholder={t("Enter Number")}
											value={callus}
											onChange={(e) => setCallus(e.target.value)}
										/>
									</InputGroup>
								</FormControl>

								{/* MailUS */}
								<FormControl id='mailus' >
									<FormLabel color='white'>{t("Mail Us")}</FormLabel>
									<Input
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Enter Mail")}
										value={mailus}
										onChange={(e) => setMailus(e.target.value)}
									/>
								</FormControl>

								{/* Appointment */}
								<FormControl id='appointment' >
									<FormLabel color='white'>{t("Book Appointment")}</FormLabel>
									<Input
										color='white'
										type='text'
										borderColor='white'
										placeholder={t("Appointment Link")}
										value={appointmentLink}
										onChange={(e) => setAppointmentLink(e.target.value)}
									/>
								</FormControl>

								<Flex gap='5' mt='6'>
									<Button
										colorScheme='teal'
										color='white'
										type='submit'
										isLoading={loading}>
										{t("Done")}
									</Button>
									<AlertDialogExample />
								</Flex>
							</Flex>
						</form>
					)}
				</FormContainer>
			</Flex>
		</Flex>
	)
}

export default SupportEditScreen;