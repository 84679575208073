import { CONV_DELETE_FAIL, CONV_DELETE_REQUEST, CONV_DELETE_SUCCESS } from "../constants/chatConstants";

export const convDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case CONV_DELETE_REQUEST:
			return { loading: true };
		case CONV_DELETE_SUCCESS:
			return { loading: false, success: true };
		case CONV_DELETE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};