import { Box, Flex, Button, Image, Heading, Icon, Text } from "@chakra-ui/react";
import { TbTrash } from 'react-icons/tb'
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { deleteEvents } from "../actions/eventsActions";

const EventsListCard = (events) => {

    const dispatch = useDispatch();

    const deleteHandler = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteEvents(id));
        }
    };

    return (
        <Flex _hover={{ textDecor: 'none' }} >
            <Flex p='10px' bgColor='white' rounded='lg' shadow='lg' w={{ sm: 'full', lg: '400px' }} h={{ sm: '110px', lg: '8rem' }} direction='row'>
                <Box as={RouterLink} to={`/events/${events.events._id}?progId=${events.progId}`}>
                    <Image border='solid' rounded='md' src={events.events.thumbImage} alt={events.events.name} h='full' w={{ sm: '94px', lg: '110px' }} />
                </Box>
                <Flex w='270px' px={{ sm: '5px', lg: '3px' }} direction='column'>
                    <Flex justifyContent='space-between' alignItems='center' >
                        <Heading as={RouterLink} to={`/events/${events.events._id}?progId=${events.progId}`} fontSize={{ sm: 'md', lg: 'lg' }} >
                            {events.events.name}
                        </Heading>
                        <Button bg='none' w={{ sm: '5', lg: '3' }} h={{ sm: '5', lg: '8' }} type='button' onClick={() => deleteHandler(events.events._id)}>
                            <Icon as={TbTrash} boxSize={{ sm: '5', lg: '6' }} fontSize='xl' fontWeight='bold' />
                        </Button>
                    </Flex>
                    <Text as={RouterLink} to={`/events/${events.events._id}?progId=${events.progId}`} p={{ sm: '3px', lg: '5px' }} overflow='clip' fontSize={{ sm: 'sm', lg: '12' }} letterSpacing='1px'>
                        {events.events.description}...
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default EventsListCard;